import React, {Component} from "react";
import AlfaRomeoRequestModelList from "../../../../components/App/forms/AlfaRomeoRequestModelList";
import AlfaRomeoRequestForm from "../../../../components/App/forms/AlfaRomeoRequestFrom";
import BaseFormPage from "../../../../components/App/forms/BaseFormPage";
import wizardStyles from "../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import styles from "./AlfaRomeo.module.css";
import AlfaromeoRequestShowroomList from "../../../../components/App/forms/AlfaromeoRequestShowroomList";
import {Container} from "react-bootstrap";
import AlfaRomeoBrochureRequestForm from "../../../../components/App/forms/AlfaRomeoBrochureRequestFrom";
import {Link} from "react-router-dom";
import {FaCheck} from "react-icons/all";

export default class AlfaRomeoForm extends BaseFormPage {
    title = "Alfa Romeo";

    render() {
        // const {currentItem, model, request} = this.state;
        const {currentItem, model, request, form} = this.state;
        console.log("state.form", form);

        return (
            <div className={styles["page-inner"]}>
                <Container className={"mb-5"}>
                    <Container className={'w-75'}>
                        <div className={`d-flex justify-content-around ${styles['flex-direction-column']}`}>
                            <Link
                                className={`${styles["btn-tabs"]} ${form.key === 'essai' && styles["active-tab"]} text-decoration-none`}
                                to={`/brands/alfaromeo/essai`}> essai</Link>
                            <Link
                                className={`${styles["btn-tabs"]} ${form.key === 'devis' && styles["active-tab"]} text-decoration-none`}
                                to={`/brands/alfaromeo/devis`}>devis</Link>
                            <Link
                                className={`${styles["btn-tabs"]} ${form.key === 'brochure' && styles["active-tab"]} text-decoration-none`}
                                to={"/brands/alfaromeo/brochure"}>Brochure</Link>
                        </div>
                    </Container>

                    {form.key !== 'brochure' && (
                        <div className={`${wizardStyles["form-wizard"]} w-50 d-block m-auto text-center `}>
                            <ol className={wizardStyles["step-indicator"]}>
                                <li className={`${(currentItem === 'profile' || currentItem === 'showroom') && wizardStyles["complete"]} ${currentItem === 'model' && wizardStyles["active-step"]} `}>
                                    <div className={wizardStyles["step"]}>
                                        <span>{currentItem === 'model' && 1} {(currentItem === 'profile' || currentItem === 'showroom') &&
                                        <FaCheck/>}</span>

                                    </div>
                                    <div
                                        className={`${wizardStyles["caption"]} ${wizardStyles["hidden-xs"]} ${wizardStyles["hidden-sm"]}`}>
                                        <span className={`${currentItem === 'model' && 'text-dark'}`}>MODÈLE</span>
                                    </div>
                                </li>

                                <li className={`${(currentItem === 'showroom' && wizardStyles["complete"])}  ${currentItem === 'profile' && wizardStyles["active-step"]} `}>
                                    <div
                                        className={wizardStyles["step"]}><span>{(currentItem === 'profile' || currentItem === 'model') && 2} {currentItem === 'showroom' &&
                                    <FaCheck/>}</span></div>
                                    <div
                                        className={`${wizardStyles["caption"]} ${wizardStyles["hidden-xs"]} ${wizardStyles["hidden-sm"]} `}>
                                        <span
                                            className={`${currentItem === 'profile' && 'text-dark'}`}>VOS COORDONNÉES</span>
                                    </div>
                                </li>

                                <li className={`${currentItem === 'showroom' && wizardStyles["active-step"]}`}>
                                    <div className={wizardStyles["step"]}>
                                        {/*<span>{(currentItem === 'profile' || currentItem === 'model' || currentItem === 'showroom') && 3} {currentItem === 'showroom' &&*/}
                                        {/*<FaCheck/>}</span>*/}
                                        <span>3</span>
                                    </div>
                                    <div
                                        className={`${wizardStyles["caption"]} ${wizardStyles["hidden-xs"]} ${wizardStyles["hidden-sm"]}`}>
                                        <span
                                            className={`${currentItem === 'showroom' && 'text-dark'}`}>DISTRIBUTEUR</span>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    )}


                    {form.key === 'brochure' && (
                        <div className={`${styles["form-wizard"]} w-50 d-block m-auto text-center `}>
                            <ol className={wizardStyles["step-indicator"]}>
                                <li className={`${(currentItem === 'profile' || currentItem === 'showroom') && wizardStyles["complete"]} ${wizardStyles["active-step"]}`}>
                                    <div className={wizardStyles["step"]}>
                                    <span>  {currentItem === 'model' && 1} {currentItem === 'profile' &&
                                    <FaCheck/>}</span>
                                    </div>
                                    <div
                                        className={`${wizardStyles["caption"]} ${wizardStyles["hidden-xs"]} ${wizardStyles["hidden-sm"]}`}>
                                        <span className={`${currentItem === 'model' && 'text-dark'}`}>MODÈLE</span></div>
                                </li>
                                <li className={`${currentItem === 'showroom' && wizardStyles["complete"]} ${currentItem === 'profile' && wizardStyles["active-step"]}`}>
                                    <div className={wizardStyles["step"]}>
                                        <span> 2 </span></div>
                                    <div
                                        className={`${wizardStyles["caption"]} ${wizardStyles["hidden-xs"]} ${wizardStyles["hidden-sm"]}`}>
                                        <span className={`${currentItem === 'profile' && 'text-dark'}`}>VOS COORDONNÉES</span>
                                    </div>
                                </li>
                            </ol>
                        </div>

                    )}
                </Container>
                <Accordion>
                    <AccordionItem
                        item={{
                            id: 1,
                            key: "model",
                            label: "Modèle",
                            visible: currentItem === "model",
                            checked:
                                currentItem === "profile" || currentItem === "showroom",
                        }}
                    >
                        <AlfaRomeoRequestModelList
                            form={form}
                            onModelClick={this.handleModelClick}
                            getCurrentModel={this.getCurrentModel}
                            currentStep={currentItem}
                        />
                    </AccordionItem>

                    <AccordionItem
                        item={{
                            id: 2,
                            key: "profile",
                            label: "Vos coordonées",
                            visible: currentItem === "profile",
                            checked: currentItem === "showroom",
                        }}
                    >
                        {form.key !== 'brochure' && (
                            <AlfaRomeoRequestForm
                                form={form}
                                model={model}
                                onSwitch={this.switchAccordionItem}
                                setRequest={this.setRequest}
                                onModelChange={this.handleModelChange}
                            />
                        )}
                        {form.key === 'brochure' && (
                            <AlfaRomeoBrochureRequestForm
                                form={form}
                                model={model}
                                onComplete={this.complete}
                                setRequest={this.setRequest}
                                onModelChange={this.handleModelChange}
                            />
                        )}

                    </AccordionItem>

                    <AccordionItem
                        item={{
                            id: 3,
                            key: "showroom",
                            label: "Distributeur",
                            visible: currentItem === "showroom",
                            checked: false,
                        }}
                    >
                        <AlfaromeoRequestShowroomList
                            form={form}
                            request={request}
                            onComplete={this.complete}
                        />
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

class Accordion extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={styles["accordion"]}>{this.props.children}</div>
            </React.Fragment>
        );
    }
}

class AccordionItem extends Component {
    render() {
        const {item} = this.props;
        const activeStatus = item.visible ? styles["active"] : "";

        console.log("item", item);

        return (
            <React.Fragment>
                <div className={`${styles["accordion__content"]} ${activeStatus}`}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}
