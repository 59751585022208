import React from 'react';
import styles from "./brands/FiatForm/Fiat.module.css";
import customStyle from "./brands/AlfaRomeoForm/AlfaRomeo.module.css";
import {FaChevronRight} from "react-icons/fa";

function TonaleThankYouPage() {
    return (
       <div>
           <h1>Merci de l’intérêt que vous portez à notre marque.
           </h1>
       </div>
    );
}

export default TonaleThankYouPage;