import React, {Component} from "react";
import styles from "../../../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import {FaChevronRight} from "react-icons/fa";

class JeepDevisThankyou extends Component {
    render() {
        const {request} = this.props;
        console.log('request', request);
        return (
            <div className={styles["page-inner"]}>
                <div className={styles["section-header"]}>
                    <h2> Merci <span
                        className={styles["fiat-red-color"]}>{request.firstName} {request.lastName}</span> de l’intérêt
                        que vous portez à notre
                        marque. </h2>

                    <span className={styles["dealer-informations"]}>
                            <h4>Votre demande a été enregistrée.<br/><br/>

                                {request.showroom && (
                                    <React.Fragment>
                                        {request.showroom.name}
                                        <br/> {request.showroom.address}
                                    </React.Fragment>
                                )}
                            </h4>
                        </span>
                    <div className={styles["thank-you-page-buttons"]}>
                        <div className={styles["buttons-container-row-lead"]}>
                            <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                                <h2>Quoi d'autre ?</h2>
                            </div>
                            <div className={`${styles["new-icon-separator-container"]} d-flex align-items-center`}>

                                {/*<div className={styles["separator"]}/>*/}
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${styles["new-icon-jeep_icon_quote"]}`}/>
                                    <div className={styles["copy-box"]}>Le Distributeur a reçu votre requête


                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${styles["new-icon-jeep_icon_calendar"]}`}/>
                                    <div className={styles["copy-box"]}>Vous serez contacté(e) dans les plus brefs
                                        délais,<br/> conformément au décret gouvernemental relatif au COVID-19,<br/> afin de
                                        convenir d’un rendez-vous
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4 "}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${styles["new-icon-jeep_icon_discovermodels"]}`}/>
                                    <div className={styles["copy-box"]}>Vous allez découvrir
                                        votre {request.model && request.model.brand && request.model.brand.name}® <br/> chez
                                        votre Distributeur et <br/> recevoir une offre personnalisée


                                    </div>
                                </div>
                            </div>
                            <div className={`${styles['new-form-submit']} ${styles['submit-jeep']} text-center m-auto`}>
                                <button onClick={() => {
                                    window.parent.location.href = "https://www.jeep.ma/"
                                }} >
                                    Revenir à l'accueil
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default JeepDevisThankyou;
