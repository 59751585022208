import React, {Component} from "react";
import styles from "./Jeep.module.css";
import {FaCheck, FaChevronDown, FaChevronUp} from "react-icons/all";
import JeepRequestModelList from "../../../../components/App/forms/JeepRequestModelList";
import RequestShowroomList from "../../../../components/App/forms/RequestShowroomList";
import JeepRequestForm from "../../../../components/App/forms/JeepRequestFrom";
import BaseFormPage from "../../../../components/App/forms/BaseFormPage";
import JeepBrochureRequestForm from "../../../../components/App/forms/JeepBrochureRequestFrom";

export default class JeepForm extends BaseFormPage {
    title = "Jeep";
    /*componentDidMount() {
        document.body.style.backgroundColor = "#333333"
    }*/

    render() {
        // const {currentItem, model, request} = this.state;
        const {currentItem, model, request, form} = this.state;
        console.log("state.form", form);
        return (
            <div className={styles["page-owner"]}>
                <div className={`${styles["form-title"]} ${styles["page-inner"]}`}>
                    <h1>demandez {form.name === 'brochure' ? "une" : "un"} {form.name}</h1>
                </div>
                <Accordion>
                    <div className={styles["page-inner"]}>
                        <Accordion>
                            <AccordionItem
                                item={{
                                    id: 1,
                                    key: "model",
                                    label: "Modèle",
                                    visible: currentItem === "model",
                                    checked:
                                        currentItem === "profile" || currentItem === "showroom",
                                }}
                            >
                                <JeepRequestModelList
                                    form={form}
                                    onModelClick={this.handleModelClick}
                                    getCurrentModel={this.getCurrentModel}
                                    currentStep={currentItem}
                                />
                            </AccordionItem>

                            <AccordionItem
                                item={{
                                    id: 2,
                                    key: "profile",
                                    label: "Vos coordonées",
                                    visible: currentItem === "profile",
                                    checked: currentItem === "showroom",
                                }}
                            >
                                {form.key !== 'brochure' && (
                                    <JeepRequestForm
                                        form={form}
                                        model={model}
                                        onSwitch={this.switchAccordionItem}
                                        setRequest={this.setRequest}
                                        onModelChange={this.handleModelChange}
                                    />
                                )}


                                {form.key === 'brochure' && (
                                    <JeepBrochureRequestForm
                                        form={form}
                                        model={model}
                                        onComplete={this.complete}
                                        setRequest={this.setRequest}
                                        onModelChange={this.handleModelChange}
                                    />)}

                            </AccordionItem>
                            {form.key !== 'brochure' && (
                                <AccordionItem
                                    item={{
                                        id: 3,
                                        key: "showroom",
                                        label: "Distributeur",
                                        visible: currentItem === "showroom",
                                        checked: false,
                                    }}
                                >
                                    <RequestShowroomList
                                        form={form}
                                        request={request}
                                        onComplete={this.complete}
                                    />
                                </AccordionItem>
                            )}
                        </Accordion>
                    </div>
                </Accordion>
            </div>
        );
    }
}

class Accordion extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={styles["accordion"]}>{this.props.children}</div>
            </React.Fragment>
        );
    }
}

class AccordionItem extends Component {
    render() {
        const {item} = this.props;
        const activeStatus = item.visible ? styles["active"] : "";

        console.log("item", item);

        /**
         *  case 1: model is active
         *   all are numbers
         *  case 2: profile form is active
         *   only model is checked
         *    if key == "profile" && visible = true
         *  case 3: showroom is active
         *   both model & profile form are checked
         *    if key == "showroom" && visible = true
         */

        return (
            <React.Fragment>
                <button className={`${styles["accordion__button"]} ${styles["bg-yellow"]}`}>
         <span>
         <span
             className={`${styles["new-form-header-number"]} ${item.checked || item.visible ? styles["bg-yellow"] : ''} }`}>
            {item.checked ? <FaCheck/> : item.id}
          </span>
          <span
              className={`${styles["new-form-header-title"]} ${item.visible ? styles["bb-yellow"] : ""} `}
          >

            {item.label}
          </span>
          </span>
                    {item.visible && <FaChevronUp/>}
                    {!item.visible && <FaChevronDown/>}
                </button>
                <div className={`${styles["accordion__content"]} ${activeStatus}`}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}
