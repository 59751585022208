import React, {Component} from "react";
import {FaChevronRight} from "react-icons/fa";

// import styles from "../../forms/brands/FiatForm/Abarth.module.css";
import styles from "../../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import customStyle from "../../../../../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";

class AbarthEssaiThankYou extends Component {
    render() {
        const {request} = this.props;
        return (
            <div className={styles["page-inner"]}>
                <div className={styles["section-header"]}>
                    <h2 style={{color: '#FFFFFF', textAlign: 'center', fontWeight: 'bold'}}> Merci <span
                        className={styles["fiat-red-color"]}>{request.firstName} {request.lastName}</span> de l’intérêt
                        que vous portez à notre
                        marque. </h2>

                    <span className={styles["dealer-informations"]}
                          style={{color: '#FFFFFF', textAlign: 'center', fontWeight: 'bold'}}>
                            <h4 className={'fs-6'}>Votre demande a été enregistrée.<br/><br/>
                                {request.showroom && (
                                    <React.Fragment>
                                        {request.showroom.name}
                                        <br/> {request.showroom.address}
                                    </React.Fragment>
                                )}
                                </h4>
                        </span>

                    <div className={styles["thank-you-page-buttons"]}>
                        <div className={styles["buttons-container-row-lead"]}>
                            <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                                <h3 style={{color: '#FFFFFF', textAlign: 'center', fontWeight: 'bold'}}>Quoi d'autre
                                    ?</h3>
                            </div>
                            <div className={`${styles["new-icon-separator-container"]}`}>

                                {/*<div className={styles["separator"]}/>*/}
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${customStyle["new-icon-alfa-romeo_icon_quote"]}`}/>
                                    <div className={styles["copy-box"]}
                                         style={{color: '#FFFFFF', textAlign: 'center'}}>Le Distributeur a reçu votre
                                        requête
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${customStyle["new-icon-alfa-romeo_icon_calendar"]}`}/>
                                    <div className={styles["copy-box"]}
                                         style={{color: '#FFFFFF', textAlign: 'center'}}>Vous serez contacté(e) dans les
                                        plus
                                        brefs délais, conformément au décret gouvernemental relatif au COVID-19, afin de
                                        convenir d’un rendez-vous
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${customStyle["new-icon-alfa-romeo_icon_testdrive"]}`}/>
                                    <div className={styles["copy-box"]}
                                         style={{color: '#FFFFFF', textAlign: 'center'}}
                                    >Vous allez apprécier la route
                                        avec {request.model && request.model.brand && request.model.brand.name}® !
                                    </div>
                                </div>
                            </div>
                            <div className={`mt-lg-5`}>
                                <button
                                    onClick={() => {
                                        window.parent.location.href = "https://www.abarth.ma/"
                                    }}
                                    className={customStyle["anagraphic-btn"]}
                                >
                                    ACCUEIL <FaChevronRight/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AbarthEssaiThankYou;
