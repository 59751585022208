import React, {useState} from "react";
import styles from "../../../pages/forms/brands/FiatForm/Fiat.module.css";

const Input = ({name, label, error, value, classes, containerClasses, ...rest}) => {
        const [correct, setCorrect] = useState(false);

        const isCorrect = () => {
            if (!error && value !== '') setCorrect(true);
            else setCorrect(false);
        }

        return (
            <div className={`${containerClasses}  ${error && "bb-red"} ${correct && "bb-green"} `}>
                {error && <span className="alert alert-danger">{label} {error}</span>}
                {correct && <span className="alert alert-success">Confirmé</span>}
                <input {...rest} name={name} id={name}
                       className={` ${classes}`}
                       onBlur={() => isCorrect()}/>
                <label htmlFor={name} className={`${value && styles["is-not-empty"]}`}>{label}</label>

            </div>
        );
    }
;

export default Input;
