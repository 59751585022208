import React from "react";
import * as Joi from "joi-browser";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import Form from "../../../Form";
import axios from "axios";

class RequestForm extends Form {
  state = {
    data: {
      model: "",
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      city: "",
      acceptedMarketingActivity: "",
    },
    errors: {},
    cities: [],
    selectedModel: {},
    isDisclaimerOpen: false,
    loading: false,
  };

  schema = {
    gender: Joi.string().required().label("Civilité"),
    lastName: Joi.string().required().min(2).max(30).label("Nom"),
    firstName: Joi.string().required().min(2).max(30).label("Prénom"),
    email: Joi.string()
      .required()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .label("Email"),
    telephone: Joi.string()
      .required()
      .regex(/^(0)([0-9]){9}$/i)
      .label("Téléphone"),
    city: Joi.string().required().label("Ville"),
    model: Joi.string().allow("", null).label("Modèle"),
    acceptedMarketingActivity: Joi.boolean()
      .required()
      .label("J'accepte l'activité du marketing"),
  };

  componentDidMount() {
    this.getCities();
  }

  setDisclaimerOpen() {
    this.setState({ isDisclaimerOpen: !this.state.isDisclaimerOpen });
  }

  getUtm() {
    // get url
    const url_string = window.location.href; //
    const url = new URL(url_string);

    if (url.searchParams.get("utm_campaign")) {
      return {
        utmCampaign: url.searchParams.get("utm_campaign"),
        utmSource: url.searchParams.get("utm_source"),
        utmMedium: url.searchParams.get("utm_medium"),
      };
    }
    return {};
  }

  doSubmit = async () => {
    this.setState({ loading: true });

    const { data } = this.state;

    const { model: currentModel, form } = this.props;

    data.model = `/models/${currentModel.id}`;

    if (data.city === "") {
      delete data.city;
    }

    if (data.telephone === "") {
      delete data.telephone;
    }

    const { utmSource, utmCampaign, utmMedium } = this.getUtm();
    const finalData = { ...data, utmSource, utmMedium, utmCampaign };

    const apiHelper = new ApiResourceHelper(form.endpoint);

    // get the city name
    const apiHelperCities = new ApiResourceHelper("/cities");
    const { data: cityValue } = await apiHelper.findOne(this.state.data.city);

    //get the model name
    const apiHelperModels = new ApiResourceHelper("/models");
    const { data: model } = await apiHelper.findOne(this.state.data.model);

    // get formetd requestType
    const requestTypeFormat = [
      { key: "devis", value: "Devis commercial" },
      { key: "essai", value: "Demande de Test Drive" },
    ];
    const requestType = requestTypeFormat.find(
      (requestType) => requestType.key === form?.name
    );

    //get formated greeting
    const greetingFormat = [
      { key: "mister", value: "M." },
      { key: "lady", value: "Mme." },
      { key: "miss", value: "Mlle." },
    ];
    const greeting = greetingFormat.find(
      (greeting) => greeting.key === this.state.data.gender
    );

    // sales force body to send
    const saleForceData = {
      greeting: greeting.value, //{'M.', 'Mme.','Mlle.','Dr.','Prof.'}
      firstName: this.state.data.firstName,
      lastName: this.state.data.lastName,
      phone: this.state.data.telephone,
      email: this.state.data.email,
      brand: model.brand.name,
      model: model.name,
      city: cityValue.name,
      source: "website", // { 'website','chatbot','landing_page','event_website','bol','ocarz'}
      formSource: window.location.href,
      requestType: requestType.value, // {'Infos','Devis commercial','Demande de Test Drive'}
    };

    // get utms
    const url_string = window.location.href; //
    const url = new URL(url_string);

    const salesForceLead = {
      ...saleForceData,
      utmCampaign: url.searchParams.get("utm_campaign"),
      utmSource: url.searchParams.get("utm_source"),
      utmMedium: url.searchParams.get("utm_medium"),
    };

    try {
      console.log("this is final data", salesForceLead);

      // send lead to salesforce endpoint
      axios
        .post("https://fca-salesforce-hub.fcamaroc.net/leads", salesForceLead)
        .then(function (response) {
          console.log("response", response);
        })
        .catch(function (error) {
          console.log("error", error);
        });

      // send whats_app_leads to salesforce endpoint
      // axios
      //   .post(
      //     "https://fca-salesforce-hub.fcamaroc.net/whats_app_leads",
      //     salesForceLead
      //   )
      //   .then(function (response) {
      //     console.log("response", response);
      //   })
      //   .catch(function (error) {
      //     console.log("error", error);
      //   });

      const { data: request } = await apiHelper.create(finalData);

      const { onSwitch, setRequest } = this.props;
      setRequest(request);
      onSwitch("showroom");
    } catch (er) {}

    this.setState({
      loading: false,
    });
  };

  getCities = async () => {
    const cityHelper = new ApiResourceHelper("/cities?published=1");
    const { data: cities } = await cityHelper.findAll();
    this.setState({ cities });
  };

  getCity_list = () => {
    const { cities } = this.state;

    const city_list = [{ key: "", value: null }];

    cities.map((city) => {
      city_list.push({
        key: `${city.name}`,
        value: `/cities/${city.id}`,
      });

      return true;
    });

    return city_list;
  };
}

export default RequestForm;
