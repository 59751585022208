import React, {Component} from "react";
import {FaChevronRight} from "react-icons/fa";

// import styles from "../../forms/brands/FiatForm/Abarth.module.css";
import styles from "../../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import customStyles from "../../../../../pages/forms/brands/FiatProForm/FiatPro.module.css";

class FiatProEssaiThankYou extends Component {
    render() {
        const {request} = this.props;
        console.log('request', request);
        return (
            <div className={styles["page-inner"]}>
                <div className={styles["section-header"]}>
                    <h2 className={'text-center text-dark'}> Merci <span
                        className={styles["fiat-red-color"]}>{request.firstName} {request.lastName}</span> de l’intérêt
                        que vous portez à notre
                        marque. </h2>

                    <span className={styles["dealer-informations"]}>
                            <h4>Votre demande a été enregistrée.<br/><br/>
                                {request.showroom && (
                                    <React.Fragment>
                                        {request.showroom.name}
                                        <br/> {request.showroom.address}
                                    </React.Fragment>
                                )}
                                </h4>
                        </span>

                    <div className={styles["thank-you-page-buttons"]}>
                        <div className={styles["buttons-container-row-lead"]}>
                            <div className={`${styles["copy-box"]} ${styles["answerTitle"]} text-dark`}>
                                <h3 className={"text-dark fw-bold"}>Quoi d'autre ?</h3>
                            </div>
                            <div className={`${styles["new-icon-separator-container"]} d-flex`}>

                                {/*<div className={styles["separator"]}/>*/}
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${customStyles["new-icon-fiat-professional_icon_quote"]}`}/>
                                    <div className={styles["copy-box"]}>Le Distributeur a reçu votre requête
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${customStyles["new-icon-fiat-professional_icon_calendar"]}`}/>
                                    <div className={styles["copy-box"]}>Vous serez contacté(e) dans les plus
                                        brefs délais, conformément au décret gouvernemental relatif au COVID-19, afin de
                                        convenir d’un rendez-vous
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${customStyles["new-icon-fiat-professional_icon_testdrive"]}`}/>
                                    <div className={styles["copy-box"]}>Vous allez apprécier la route
                                        avec {request.model && request.model.brand && request.model.brand.name}® !
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles["containerCTATYP"]} mt-lg-5`}>
                                <a onClick={() => {
                                    window.parent.location.href = "https://www.fiatprofessional.com/ma";
                                }}
                                   className={customStyles["rect-btn-red"]}>
                                    ACCUEIL <FaChevronRight/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FiatProEssaiThankYou;
