import React from "react";
import {Image} from "react-bootstrap";
import RequestModelList from "../RequestModelList";
import styles from "../../../../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";
import {FaCircle} from "react-icons/all";
import queryString from "querystring";

class AlfaRomeoRequestModelList extends RequestModelList {
    brandSlug = "alfa-romeo";

    render() {
        const {loaded} = this.state;
        console.log("this.props", this.props);
        const {onModelClick} = this.props;

        const models = this.getFilteredModels();

        if (loaded) {
            return (
                <React.Fragment>
                    <div className="section-header title-form">
                        <h2
                            style={{
                                fontFamily:"Apex New Medium",
                                fontSize:"16px",
                                textTransform:"inherit",
                                textAlign:"left",
                                margin:"0 "
                            }}
                        >Sélectionnez un modèle</h2>
                    </div>
                    <div className={styles["new-form-step-content-list-flex"]}>
                        {models.map((m) => (
                            <div className={`${styles["new-form-step-content-list-item"]} ${styles["wrap-car-model"]}`}
                                 key={m.id}>
                                <div className={styles["new-form-vehicle"]} onClick={() => onModelClick(m)}>
                                    <Image
                                        src={m.image && `${process.env.REACT_APP_BASE_API}/uploads/images/${m.image.filePath}`}
                                        className={`img-fluid ${styles["new-form-vehicle-image"]}`}
                                    />
                                    <div className={styles["new-form-vehicle-title"]}><FaCircle
                                        className={`${styles["new-form-vehicle-link"]} ${styles["step-cars"]}]`}
                                        onClick={() => onModelClick(m)}

                                        style={{
                                            backgroundColor: "transparent",
                                            color: "transparent",
                                            border: "1px solid #000",
                                            borderRadius: "20px"
                                        }}
                                    > </FaCircle> {m.name} </div>


                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}

export default AlfaRomeoRequestModelList;
