import React, {Component} from "react";
import Joi from "joi-browser";
import {FaLongArrowAltRight, FaSave} from "react-icons/fa";

import Input from "./Input";
import Select from "./Select";
import Button from "./Button";
import RadioInput from "./RadioInput";
import {FaChevronRight} from "react-icons/all";
import {icons} from "react-icons";

class Form extends Component {
    validate = () => {
        const options = {
            abortEarly: false,
        };
        const {error} = Joi.validate(this.state.data, this.schema, options);
        console.log("joi error", error);

        if (!error) return null;

        const errors = {};
        for (let item of error.details) {
            //errors[item.path[0]] = item.message;
            errors[item.path[0]] = 'incorrect';
        }

        return errors;
    };

    validateProperty = ({name, value}) => {
        const obj = {[name]: value};
        const schema = {[name]: this.schema[name]};
        const {error} = Joi.validate(obj, schema);
        //return error ? error.details[0].message : null;
        return error ? 'incorrect' : null;
    };

    handleSubmit = (e) => {
        e.preventDefault();

        console.log('state.data', this.state.data);

        const errors = this.validate();
        console.log("errors", errors);

        this.setState({errors: errors || {}});
        if (errors) return;

        this.doSubmit();
    };

    handleChange = ({currentTarget: input}) => {
        console.log("input", input);

        const errors = {...this.state.errors};
        const errorMessage = this.validateProperty(input);

        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = {...this.state.data};

        if (input.type === "number") {
            data[input.name] = parseInt(input.value);
        } else if (input.type === "boolean") {
            data[input.name] = input.value === "true";
        } else {
            data[input.name] = input.value;
        }

        this.setState({data, errors});
    };

    renderButton(label, inlineCSS) {
        return (
            <Button
                type="submit"
                label={label}
                cssClass="btn"
                inlineCSS={inlineCSS}
            />
        );
    }

    renderFiatFormBtn(label) {
        return (
            <Button
                type="submit"
                label={label}
                icon={FaLongArrowAltRight}
                cssClass="btn"
            />
        );
    }

    renderJeepFormBtn(label) {
        return (
            <Button
                type="submit"
                label={label}
                icon={FaLongArrowAltRight}
                cssClass="btn"
            />
        );
    }

    renderInput(name, type, label, classes, containerClasses) {
        const {data, errors} = this.state;

        return (
            <Input
                type={type}
                name={name}
                label={label}
                value={data[name]}
                onChange={this.handleChange}
                error={errors[name]}
                containerClasses={containerClasses}
                classes={classes}
            />
        );
    }

    renderSelect(name, label, options, classes,containerClasses) {
        const {data, errors} = this.state;

        return (
            <Select
                value={data[name]}
                name={name}
                label={label}
                options={options}
                onChange={this.handleChange}
                error={errors[name]}
                containerClasses={containerClasses}
                classes={classes}
            />
        );
    }

    renderRadioInput(name, label, options, classes, containerClasses, inlineCSS, customStyle) {
        const {data, errors} = this.state;

        return (
            <RadioInput
                value={data[name]}
                name={name}
                label={label}
                options={options}
                error={errors[name]}
                onChange={this.handleChange}
                containerClasses={containerClasses}
                classes={classes}
                inlineCSS = {inlineCSS}
                customStyle = {customStyle}
            />
        );
    }
}

export default Form;
