import React from "react";
import {Image} from "react-bootstrap";
import RequestModelList from "../RequestModelList";
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/all";

class JeepRequestModelList extends RequestModelList {
    brandSlug = "jeep";

    render() {
        const {loaded} = this.state;
        console.log("this.props", this.props);

        const {onModelClick} = this.props;

        const models = this.getFilteredModels();
        console.log("get filtered modules", models);
        if (loaded) {
            return (
                <React.Fragment>

                    <div className={`${styles["section-header"]} ${styles["title-form"]}`}>
                        <h2>SÉLECTIONNEZ UN MODÈLE</h2>
                    </div>
                    <div className={styles["new-form-step-content-list-flex"]}>

                        {models.map((m) => (
                            <div className={`${styles["new-form-step-content-list-item"]} ${styles["wrap-car-model"]}`}
                                 key={m.id}>
                                <div className={styles["new-form-vehicle"]} onClick={() => onModelClick(m)}>
                                    <Image
                                        src={m.image && `${process.env.REACT_APP_BASE_API}/uploads/images/${m.image.filePath}`}
                                        className={`img-fluid ${styles["new-form-vehicle-image"]}`}
                                    />
                                    <div className={styles["new-form-vehicle-title"]}> {m.name} </div>
                                    <div className={styles["new-form-vehicle-subtitle"]}>
                                        {m.description}
                                    </div>

                                    <button className={`${styles["new-form-vehicle-link"]} ${styles["step-cars"]}]`}
                                          onClick={() => onModelClick(m)}>Sélectionnez <FaChevronRight
                                        className={"new-icon-jeep_arrow_cta"} style={{color: "#ffba00"}}/> </button>
                                </div>
                            </div>

                        ))}
                    </div>
                </React.Fragment>
            );

        } else {
            return <div>Loading...</div>;
        }
    }
}

export default JeepRequestModelList;
