import React, {Component, useState} from 'react';
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import {Collapse} from "react-bootstrap";

export default function Disclaimer({open}) {
    return (
        <React.Fragment>

            <Collapse in={open} className={"mt-3 mb-3"}>
                <div id={styles["note-infos"]}>
                    <div className={"form-radios-group-infos-content"}>
                        <div className="privacyMask"></div>

                        {/*<iframe*/}
                        {/*    src="https://staticpromo.fiat.com/privacy/FR/informative-fr.html"*/}
                        {/*    data-gtm-yt-inspected-1_27="true"*/}
                        {/*    style={{width: "100%", height: "155px"}}*/}
                        {/*/>*/}

                        <div className={"boxMessage"}>
                            <p className={"privacy-title pt-3 pb-0"}>
                                <u> Recueil du consentement et information</u>
                            </p>
                        </div>
                            <p>FIAT CHRYSLER AUTOMOBILE MOROCCO traite vos données personnelles.</p>
                            <p>Conformément à la Loi 09-08, vous pouvez accéder aux informations vous concernant, les rectifier ou les supprimer, par courrier à MOTOR VILLAGE Ouled Benameur, RP 3011, KM 6, Bouskoura (seules les demandes signées, accompagnées de la photocopie d’une pièce d’identité seront traitées).</p>
                            <p>Ce traitement de données a été déclaré auprès de la CNDP sous le numéro D-M-344/2017</p>
                        </div>
                </div>
            </Collapse>
        </React.Fragment>
    );
}

