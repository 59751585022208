import React from "react";

import {Link} from "react-router-dom";
import {Col, Image, Row} from "react-bootstrap";
import {FaChevronLeft} from "react-icons/all";
import NoteFormation from "../NoteFormation";
import RequestForm from "../RequestForm";
import styles from "../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import stylesFiatPro from "../../../../pages/forms/brands/FiatProForm/FiatPro.module.css";
import Disclaimer from "../Disclamer/Disclamer";
import Loader from "react-loader-spinner";

class FiatProRequestForm extends RequestForm {
    render() {
        const {model, onModelChange, form} = this.props;
        const {loading} = this.state;
        return (
            <div>
                <div className={`${stylesFiatPro["section-header"]} ${stylesFiatPro["form-models-list"]}`}>
                    <h2 className={"text-center text-dark w-100"}>Remplissez le formulaire</h2>
                </div>
                <Row>
                    <div className={"col-md-5"}>
                        <span className={styles["title-form"]}>Votre sélection :</span>
                        {model && model.id && (
                            <div>
                                <p className={stylesFiatPro["new-form-img-title"]}>{model.name}</p>
                                <div className={styles["new-form-img"]}>
                                    <Image
                                        src={model.image && `${process.env.REACT_APP_BASE_API}/uploads/images/${model.image.filePath}`}
                                        className={`${styles["img-fluid"]} ${styles["img-anagraphic"]} shadow-lg`}
                                    />
                                    <Link
                                        className={`${styles["new-form-link"]} ${styles["new-form-link-change"]} ${styles["model"]} text-dark text-decoration-none`}
                                        onClick={() => onModelChange()}
                                    >
                                        <FaChevronLeft/>
                                        Changer de modèle
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={"col-md-7 mt-lg-5"}>
                        <div className={styles["form-anagrafic-container"]}>
                            <form
                                onSubmit={this.handleSubmit}
                                className={`${styles["formAnagrafic"]} ${styles["mui-form"]} pt-lg-4 `}
                            >
                                <Row>
                                    <div className={"col-xs-12 col-sm-6"}>
                                        <div className={`${styles["new-select-form"]} required-field`}>
                                            {this.renderSelect("gender", "Civilité*", [
                                                {key: "", value: null},
                                                {key: "Mr.", value: "mister"},
                                                {key: "Mme.", value: "lady"},
                                                {key: "Mlle.", value: "miss"},
                                            ], `${styles['dropdownSelect']}`)}

                                            {/*<div className="dropdownSelect" data-name={"gender"}/>*/}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <Col
                                        md={6}>{this.renderInput("firstName", "text", "Prénom*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    <Col
                                        md={6}>{this.renderInput("lastName", "text", "Nom*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={6}>{this.renderInput("email", "email", "Email*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    {form.key !== 'brochure' && (
                                        <Col
                                            md={6}>{this.renderInput("telephone", "tel", "Téléphone*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    )}
                                </Row>
                                {form.key !== 'brochure' && (
                                    <Row>
                                        <Col md={6}>
                                            <div className={`${styles["new-select-form"]} required-field`}>
                                                {this.renderSelect("city", "Ville*", this.getCity_list(), `${styles['dropdownSelect']}`)}
                                            </div>

                                        </Col>
                                        {/*<Col md={6}>{this.renderInput("address", "text", "Adresse", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>*/}
                                    </Row>)}

                                <Row>
                                    <div className={"col-xs-12"}>
                                        <div className={"new-form-text"}>
                                            <div className={styles["new-form-text-title"]}>CONSENTEMENT</div>
                                            <div className={styles["new-form-text-content"]}>
                                                Après avoir lu la
                                                <NoteFormation/>
                                            </div>
                                            <div className={"d-flex align-items-center justify-content-start"}>
                                                {this.renderRadioInput("acceptedMarketingActivity", " ", [
                                                    {key: "J’accepte", value: true},
                                                    {key: "Je refuse", value: false},
                                                ], `${styles['form-check-input']}`, "", {})}

                                                <a
                                                    onClick={() => this.setDisclaimerOpen()}
                                                    aria-controls={styles["note-infos"]}
                                                    aria-expanded={this.state.isDisclaimerOpen}
                                                    className={`${styles["new-form-linktext"]} m-0`}

                                                >
                                                    <u>Conditions générales d'utilisation</u>
                                                </a>
                                            </div>
                                        </div>
                                        <Disclaimer open={this.state.isDisclaimerOpen}/>
                                    </div>
                                </Row>

                                <div className={"col-xs-12"}>
                                    <div
                                        className={`${styles['new-form-submit']} ${styles['submit']} ${styles["btn-red-shadow"]}`}>
                                        {!loading ? (
                                            this.renderButton("ÉTAPE SUIVANTE")
                                        ) : (
                                            <div style={{textAlign: 'center'}}>
                                                <Loader
                                                    type="TailSpin"
                                                    color="#00BFFF"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Row>
            </div>
        );
    }
}

export default FiatProRequestForm;
