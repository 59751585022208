import React, { Component } from "react";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import showroomStyles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import { Card, Col, Row } from "react-bootstrap";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

class RequestShowroomList extends Component {
  state = {
    request: {},
    showrooms: [],
    loaded: false,
    hasShowroom: false,
  };

  componentDidMount() {
    console.log("mounted");

    const { request } = this.props;

    if (request && request.city) {
      const { request } = this.props;
      this.setState({ request });
      console.log("im here");

      this.getShowroomsByCity(request.city);
    }
  }

  componentDidUpdate(prevProps) {
    console.log("updated");
    if (prevProps.request !== this.props.request && this.props.request.city) {
      const { request } = this.props;

      this.getShowroomsByCity(request.city);

      this.setState({ request: request });
    }
  }

  handleShowroomClick = async (showroom) => {
    // set showroom
    const { request } = this.state;
    const { form } = this.props;
    console.log("showroom form", form);

    const apiHelper = new ApiResourceHelper(form.endpoint);
    const { data: requestUpdated } = await apiHelper.update(
      `${form.endpoint}/${request.id}`,
      {
        ...request,
        showroom: `/showrooms/${showroom.id}`,
        model: `/models/${request.model.id}`,
        city: `/cities/${request.city.id}`,
      }
    );

    this.setState({ request: requestUpdated });

    const { onComplete } = this.props;
    // switch to the next accordion item
    onComplete();
  };

  getShowrooms = async () => {
    const showroomHelper = new ApiResourceHelper("/showrooms");
    const { data: showrooms } = await showroomHelper.findAll();
    console.log("showrooms test", showrooms);

    this.setState({ showrooms, loaded: true });
  };

  getShowroomsByCity = async (city) => {
    const showroomHelper = new ApiResourceHelper("/showrooms");
    const { data: showrooms } = await showroomHelper.findBy([
      { key: "city", value: `cities/${city.id}&tags=jeep` },
    ]);

    console.log("showrooms by city", showrooms);

    if (showrooms.length) {
      this.setState({ showrooms, loaded: true, hasShowroom: true });
    }
    //   else {
    //   this.getShowrooms();
    // }
  };

  render() {
    const { showrooms, loaded, hasShowroom, request } = this.state;
    console.log("showrooms", showrooms);
    if (loaded) {
      return (
        <div>
          <div
            className={`${showroomStyles["section-header"]} ${showroomStyles["title-form"]}`}
          >
            <h1 className={showroomStyles["text-start"]}>
              Sélectionnez un de nos distributeurs
            </h1>
          </div>
          {hasShowroom && request.city && (
            <Row>
              <div
                className={`${showroomStyles["showroom-city"]} text-uppercase mb-3`}
              >
                {request.city.name}
              </div>
            </Row>
          )}
          <Row>
            {showrooms.map((s) => (
              <Col md={4} key={s.id} className={"mb-5 "}>
                <Card
                  className={`shadow-sm p-3 w-100 ${showroomStyles["showroom-card"]}`}
                  onClick={() => this.handleShowroomClick(s)}
                >
                  {!hasShowroom && s.city && (
                    <p
                      className={`${showroomStyles["showroom-city"]} text-uppercase fw-bold pb-4`}
                    >
                      {s.city.name}
                    </p>
                  )}

                  <p
                    className={`${showroomStyles["showroom-title"]} text-uppercase fw-bold pb-4`}
                  >
                    {s.name}
                  </p>
                  <p
                    className={`${showroomStyles["showroom-address"]} text-uppercase fw-bold pb-4`}
                  >
                    <FaMapMarkerAlt /> {s.address}
                  </p>
                  <p
                    className={`${showroomStyles["showroom-address"]} text-uppercase fw-bold pb-4`}
                  >
                    <FaPhoneAlt /> {s.telephone}
                    {s.telephoneSecondary && (
                      <React.Fragment>/ {s.telephoneSecondary}</React.Fragment>
                    )}
                  </p>
                  <div
                    className={`${styles["new-form-submit"]} ${styles["submit-jeep"]}`}
                  >
                    <button onClick={() => this.handleShowroomClick(s)}>
                      Séléctionez
                    </button>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      );
    } else {
      return (
        <>
          {showrooms.length === 0 ? (
            <div>no showroom available in this city...</div>
          ) : (
            <div>Loading...</div>
          )}
        </>
      );
    }
  }
}

export default RequestShowroomList;
