import React, {useState} from "react";
import styles from "../../../pages/forms/brands/FiatForm/Fiat.module.css";

const Select = ({name, label, error, value, options, containerClasses, classes, ...rest}) => {
    const [correct, setCorrect] = useState(false);

    const isCorrect = () => {
        if (!error && value !== '') setCorrect(true);
        else setCorrect(false);
    }
    return (
        <div className={`${containerClasses} form-group`}>
            <label htmlFor={name} className={`p-abs ${value && styles["is-not-empty"]}`}>{label}</label>
            {error && <div className="alert alert-danger">{error}</div>}
            {correct && <span className="alert alert-success">Confirmé</span>}
            <select name={name} id={name} {...rest}
                    className={`${error && "bb-red"} ${correct && "bb-green"} ${classes} `}
                    onBlur={() => isCorrect()}
            >
                {options.map((o) => (
                    <option value={o.value} key={o.value}>
                        {o.key}
                    </option>
                ))}
            </select>

        </div>
    );
};

export default Select;
