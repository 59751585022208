import React, { Component } from "react";
import styles from "../../../../../../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";
import customStyles from "../../../../../../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";
import { FaAngleRight } from "react-icons/all";

class AlfaromeoBrochureThankyou extends Component {
  render() {
    const { request } = this.props;
    console.log("request", request);
    return (
      <div className={styles["page-inner"]}>
        <div className={styles["section-header"]}>
          <h3 className={"text-center fw-bold text-dark"}>
            {" "}
            Merci{" "}
            <span className={styles["fiat-red-color"]}>
              {request.firstName} {request.lastName}
            </span>{" "}
            de l’intérêt que vous portez à notre marque.{" "}
          </h3>

          <div className={styles["thank-you-page-buttons"]}>
            <div className={styles["buttons-container-row-lead"]}>
              <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                <h3 className={"text-dark text-center fw-bold"}>
                  Quoi d'autre ?
                </h3>
              </div>
              <div
                className={`${styles["new-icon-separator-container"]} d-flex`}
              >
                {/*<div className={styles["separator"]}/>*/}
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${customStyles["new-icon-alfa-romeo_icon_brochure"]}`}
                  />
                  <div className={customStyles["copy-box"]}>
                    Téléchargez votre brochure. <br /> Vous allez également
                    recevoir un email avec un lien pour la télécharger.
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${customStyles["new-icon-alfa-romeo_icon_discovermodels"]}`}
                  />
                  <div className={`${customStyles["copy-box"]} fw-light`}>
                    Découvrez les brochures des autres modèles
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${customStyles["new-icon-alfa-romeo_icon_discoverpromotions"]}`}
                  />
                  <div className={customStyles["copy-box"]}>
                    Découvrez toutes les promotions{" "}
                    {request.model &&
                      request.model.brand &&
                      request.model.brand.name}
                    !
                  </div>
                </div>
              </div>
              <div className={` mt-lg-5 d-flex justify-content-around`}>
                <a
                  className={`${customStyles["rect-btn-red"]} text-decoration-none `}
                  href={request.model && request.model.brochureUrl}
                  download
                >
                  Telecharger <FaAngleRight />
                </a>

                <button
                  onClick={() => {
                    window.parent.location.href =
                      "https://www.alfaromeo.co.ma/brochure";
                  }}
                  className={customStyles["rect-btn-red-sec"]}
                >
                  En savoir plus <FaAngleRight />
                </button>
                <button
                  onClick={() => {
                    window.parent.location.href =
                      "https://www.alfaromeo.co.ma/offres-alfa-romeo";
                  }}
                  className={customStyles["rect-btn-red-sec"]}
                >
                  voir les promotions <FaAngleRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlfaromeoBrochureThankyou;
