import React, { Component } from "react";
import styles from "../../../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import { Container } from "react-bootstrap";

class FiatBrochureThankyou extends Component {
  render() {
    const { request } = this.props;
    console.log("request", request);
    return (
      <div className={styles["page-inner"]}>
        <div className={styles["section-header"]}>
          <h2 className={`${styles["thanks-title"]} text-center`}>
            {" "}
            Merci{" "}
            <span className={styles["fiat-red-color"]}>
              {request.firstName} {request.lastName}
            </span>{" "}
            de l’intérêt que vous portez à notre marque.{" "}
          </h2>

          <Container>
            <div className={styles["thank-you-page-buttons"]}>
              <div className={styles["buttons-container-row-lead"]}>
                <div
                  className={`${styles["copy-box"]} ${styles["answerTitle"]}`}
                >
                  <h2>Quoi d'autre ?</h2>
                </div>
                <div
                  className={`${styles["new-icon-separator-container"]} d-flex`}
                >
                  {/*<div className={styles["separator"]}/>*/}
                  <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                    <div
                      className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${styles["new-icon-fiat_icon_brochure"]}`}
                    />
                    <div className={styles["copy-box"]}>
                      Téléchargez votre brochure. Vous allez également recevoir
                      un email avec un lien pour la télécharger.
                    </div>
                  </div>
                  <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                    <div
                      className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${styles["new-icon-fiat_icon_discovermodels"]}`}
                    />
                    <div className={styles["copy-box"]}>
                      Découvrez les brochures des autres modèles
                    </div>
                  </div>
                  <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                    <div
                      className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${styles["new-icon-fiat_icon_discoverpromotions"]}`}
                    />
                    <div className={styles["copy-box"]}>
                      Découvrez toutes les promotions{" "}
                      {request.model &&
                        request.model.brand &&
                        request.model.brand.name}
                      !
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles["submit-fiat"]} mt-lg-5 d-flex justify-content-around`}
                >
                  <a
                    className={`${styles["rect-btn-red"]} text-decoration-none`}
                    href={request.model && request.model.brochureUrl}
                    download
                  >
                    Telecharger
                  </a>
                  <button
                    onClick={() => {
                      window.location.href = "/brands/fiat/brochure";
                    }}
                    className={styles["rect-btn-grey"]}
                  >
                    En savoir plus
                  </button>
                  <button
                    onClick={() => {
                      window.parent.location.href =
                        "https://www.fiat.ma/voitures-neuves";
                    }}
                    className={styles["rect-btn-grey"]}
                  >
                    voir les offres
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default FiatBrochureThankyou;
