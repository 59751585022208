import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Form from "../../components/Form";

class Home extends Form {
    render() {
        return (
            <Container className="mt-5 form-card shadow">
            </Container>
        );
    }
}

export default Home;