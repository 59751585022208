import React, { useEffect, useState } from "react";
import axios from "axios";
import * as Joi from "joi-browser";
import alfaromeoStyles from "../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";
import { Col, Container, Row } from "react-bootstrap";
import NoteFormation from "../components/App/forms/NoteFormation";
import Disclaimer from "../components/App/forms/Disclamer/Disclamer";
import styles from "./forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";
import TonalCarImage from "../assets/images/alfaromeo-tonale.png";
import IconMap from "../assets/images/map.png";

const baseUrl = process.env.REACT_APP_BASE_API;
console.log("baseUrl", baseUrl);

const strToBool = (value) => {
  if (value && typeof value === "string") {
    if (value.toLowerCase() === "true") return true;
    if (value.toLowerCase() === "false") return false;
  }
  return value;
};

function InformationRequest() {
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [dataSaved, setDataSaved] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenDisclamer = () => {
    setOpenDisclaimer(!openDisclaimer);
  };
  const [formData, setFormData] = useState({
    email: null,
    firstName: null,
    lastName: null,
    gender: null,
    hasAcceptedMarketingActivity: null,
  });

  const [errors, setErrors] = useState(null);

  const schema = {
    gender: Joi.string().required().label("Civilité"),
    lastName: Joi.string().required().min(2).max(30).label("Nom"),
    firstName: Joi.string().required().min(2).max(30).label("Prénom"),
    email: Joi.string()
      .required()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .label("Email"),
    hasAcceptedMarketingActivity: Joi.boolean()
      .required()
      .label("Activité du marketing"),
  };

  const validate = () => {
    const options = {
      abortEarly: false,
    };
    const { error } = Joi.validate(formData, schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = "incorrect";
    }

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? "incorrect" : null;
  };

  const handleChange = ({ currentTarget }) => {
    setFormData({ ...formData, [currentTarget.name]: currentTarget.value });
    console.log("formData", formData);
  };

  const handleRadioChange = ({ currentTarget }) => {
    console.log("currentTarget.value", currentTarget.value);
    console.log("currentTarget.value to bool", strToBool(currentTarget.value));
    setFormData({
      ...formData,
      [currentTarget.name]: strToBool(currentTarget.value),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors(validate());
    console.log("errors", errors);

    if (errors) return;

    try {
      setLoading(true);
      const { data: saved } = await axios.post(
        `${baseUrl}/information_requests`,
        formData
      );
      setLoading(false);
      if (saved) {
        console.log("saved", saved);
        setDataSaved(saved);
        console.log("save data", dataSaved);
      }
    } catch (e) {
      console.log("e", e.response);
    }
  };

  useEffect(() => {
    const gray = "#e5e5e5";
    document.querySelector("body").style.backgroundColor = gray;
  }, []);

  return (
    <Container>
      {!dataSaved && (
        <Row>
          <div className="row align-items-start my-5">
            <div
              className={`col-sm-5 gap-4 my-5 ${alfaromeoStyles["align-card-img"]}`}
            >
              <p>Remplissez le formulaire</p>
              <p className="fw-light">Votre sélection:</p>
              <p className="fs-4">TONALE</p>
              <img src={TonalCarImage} alt="" />
            </div>
            <div className="col-sm-7 gap-4">
              <form method="post" onSubmit={(e) => handleSubmit(e)}>
                <Row>
                  <Col md={6}>
                    <div className="position-relative">
                      <select
                        name={"gender"}
                        onChange={(e) => handleChange(e)}
                        className={alfaromeoStyles["input__styles"]}
                        onBlur={() => (errors ? "error" : "correct")}
                      >
                        <option value="">Civilité*</option>
                        <option value="male">Homme</option>
                        <option value="female">Femme</option>
                      </select>
                      <Error error={errors && errors["gender"]} />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="position-relative">
                      <input
                        type="text"
                        name={"firstName"}
                        value={formData.firstName}
                        onChange={(e) => handleChange(e)}
                        className={`${alfaromeoStyles["input__styles"]}`}
                        placeholder="Prénom*"
                      />
                      <Error error={errors && errors["firstName"]} />
                    </div>
                  </Col>
                  <Col>
                    <div className="position-relative">
                      <input
                        type="text"
                        name={"lastName"}
                        value={formData.lastName}
                        onChange={(e) => handleChange(e)}
                        className={`${alfaromeoStyles["input__styles"]} ${
                          !errors && "correct"
                        }`}
                        placeholder="Nom*"
                      />
                      <Error error={errors && errors["lastName"]} />
                    </div>
                  </Col>
                </Row>
                <div className="position-relative">
                  <input
                    type="text"
                    name={"email"}
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    className={`${alfaromeoStyles["input__styles"]} ${
                      !errors && "correct"
                    }`}
                    placeholder="Email*"
                  />
                  <Error error={errors && errors["email"]} />
                </div>

                <p>* Tous les champs sont obligatoires</p>

                <h4>Consentement</h4>
                <p className={alfaromeoStyles["all-in-black"]}>
                  Après avoir lu
                  <NoteFormation />
                </p>

                <div className={alfaromeoStyles["all-in-dark-gray"]}>
                  {[
                    { label: "J'accèpte", value: true },
                    {
                      label: "Je refuse",
                      value: false,
                    },
                  ].map((option) => (
                    <>
                      <input
                        type="radio"
                        name={"hasAcceptedMarketingActivity"}
                        value={option.value}
                        checked={
                          formData.hasAcceptedMarketingActivity === option.value
                        }
                        onChange={(e) => handleRadioChange(e)}
                        className={`${alfaromeoStyles["radio-btn"]} ${
                          errors && "error"
                        } ${!errors && "correct"}`}
                      />
                      <label className={alfaromeoStyles["radio-label"]}>
                        {option.label}
                      </label>
                    </>
                  ))}
                  <a
                    onClick={() => handleOpenDisclamer()}
                    aria-controls={styles["note-infos"]}
                    aria-expanded={openDisclaimer}
                    className={`${styles["new-form-linktext"]} mr-5`}
                  >
                    <u>Conditions générales d'utilisation</u>
                  </a>
                  <Disclaimer open={openDisclaimer} />
                </div>

                {!loading && (
                  <button
                    className={`${alfaromeoStyles["anagraphic-btn"]} mb-5`}
                    type={"submit"}
                  >
                    Envoyer
                  </button>
                )}
                {loading && (
                  <button
                    className={`${alfaromeoStyles["anagraphic-btn"]} mb-5`}
                    type={"submit"}
                    disabled="disabled"
                  >
                    Envoyer
                  </button>
                )}
              </form>
            </div>
          </div>
        </Row>
      )}
      {dataSaved && (
        <Row>
          <div className="d-block text-center">
            <h3>
              Merci{" "}
              <strong
                style={{ color: "#8F0C25" }}
              >{`${dataSaved.firstName} ${dataSaved.lastName}`}</strong>{" "}
              de l’intérêt que vous portez à notre marque.
            </h3>
            <div className="my-5">
              <div className="my-3">
                <img width="70" src={IconMap} alt="" />
              </div>
              <p className="font-light">
                Continuer votre aventure dans l'Univers Alfa Romeo
              </p>
            </div>

            <div>
              <a
                href="https://www.alfaromeo.co.ma/"
                className={`${alfaromeoStyles["anagraphic-btn"]} mb-5`}
              >
                Accueil
              </a>
            </div>
          </div>
        </Row>
      )}
    </Container>
  );
}

const Error = ({ error }) => {
  if (error) {
    return <span className={alfaromeoStyles["error"]}>{error}</span>;
  }
  return <></>;
};

export default InformationRequest;
