import React, { Component } from "react";

// import styles from "../../forms/brands/FiatForm/Abarth.module.css";
import styles from "../../../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import customStyles from "../../../../../../pages/forms/brands/FiatProForm/FiatPro.module.css";
import { FaAngleRight } from "react-icons/all";
import showroomStyles from "../../../../../../pages/forms/brands/FiatForm/Fiat.module.css";

class FiatProBrochureThankYou extends Component {
  render() {
    const { request } = this.props;
    console.log("request", request);
    return (
      <div className={styles["page-inner"]}>
        <div className={styles["section-header"]}>
          <h2 className={"text-center text-dark"}>Téléchargez une brochure</h2>
          <h2 className={"text-center text-dark"}>
            {" "}
            Merci{" "}
            <span className={`${styles["fiat-red-color"]} `}>
              {request.firstName} {request.lastName}
            </span>{" "}
            de l’intérêt que vous portez à notre marque.{" "}
          </h2>

          <span className={styles["dealer-informations"]}>
            <h4>
              Votre demande a été enregistrée.
              <br />
              <br />
              {request.showroom && (
                <React.Fragment>
                  {request.showroom.name}
                  <br /> {request.showroom.address}
                </React.Fragment>
              )}
            </h4>
          </span>

          <div className={styles["thank-you-page-buttons"]}>
            <div className={styles["buttons-container-row-lead"]}>
              <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                <h3 className={"text-dark fw-bold "}>Quoi d'autre ?</h3>
              </div>
              <div
                className={`${styles["new-icon-separator-container"]} d-flex`}
              >
                {/*<div className={styles["separator"]}/>*/}
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${customStyles["new-icon-fiat-professional_icon_brochure"]}`}
                  />
                  <div className={styles["copy-box"]}>
                    Téléchargez votre brochure. Vous allez également recevoir un
                    email avec un lien pour la télécharger.
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${customStyles["new-icon-fiat-professional_icon_discovermodels"]}`}
                  />
                  <div className={styles["copy-box"]}>
                    Découvrez les brochures des autres modèles
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${customStyles["new-icon-fiat-professional_icon_discoverpromotions"]}`}
                  />
                  <div className={styles["copy-box"]}>
                    Découvrez toutes les promotions Fiat Professional!
                  </div>
                </div>
              </div>
              <div
                className={`${showroomStyles["submit-fiat"]} mt-lg-5 d-flex justify-content-around`}
              >
                <a
                  className={customStyles["rect-btn-red"]}
                  href={request.model && request.model.brochureUrl}
                  download
                >
                  Telecharger <FaAngleRight />
                </a>
                <button
                  onClick={() => {
                    window.location.href = "/brands/fiatprofessional/brochure";
                  }}
                  className={`${customStyles["rect-btn-red-sec"]} border-0`}
                >
                  En savoir plus <FaAngleRight />
                </button>
                <button
                  onClick={() => {
                    window.parent.location.href =
                      "https://www.fiatprofessional.com/ma/promotions";
                  }}
                  className={`${customStyles["rect-btn-red-sec"]} border-0`}
                >
                  Voir les promotions <FaAngleRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FiatProBrochureThankYou;
