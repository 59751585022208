import http from "../../services/http";

class ApiResourceHelper {
  currentEndPoint = "";

  constructor(path) {
    this.currentEndPoint = `${http.endPoint}${path}`;
  }

  findAll() {
    return http.get(this.currentEndPoint);
  }

  findBy(params) {
    const query = params
      .reduce((acc, p) => acc + `${p.key}=${p.value}&`, "?")
      .slice(0, -1);

    const uri = this.currentEndPoint + query;


    return http.get(uri);
  }

  create(body) {
    return http.post(this.currentEndPoint, body);
  }

  findOne(iri) {
    return http.get(http.endPoint + iri);
  }

  update(iri, body) {
    return http.put(http.endPoint + iri, body);
  }

  patch(iri, body) {
    http.axios.defaults.headers.common["Accept"] =
      "Application/merge-patch+json";

    return http.axios.patch(http.endPoint + iri, body);
  }

  remove(iri) {
    return http.delete(http.endPoint + iri);
  }
}

export default ApiResourceHelper;
