import React, {Component} from 'react';
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import forms from "../../../../config/forms.json";
import genders from "../../../../config/genders.json";

class RequestThankyou extends Component {
    state = {
        request: {},
        loaded: false,
    }

    componentDidMount() {
        this.getRequest();
    }

    getCurrentForm = () => {
        const formKey = this.props.match.params.form;
        return forms.filter((f) => f.key === formKey)[0];
    };

    getRequest = async () => {
        const form = this.getCurrentForm();

        const requestId = this.props.match.params.request;
        const iri = `${form.endpoint}/${requestId}`;

        const apiHelper = new ApiResourceHelper(form.endpoint);
        const {data} = await apiHelper.findOne(iri);
        console.log('request', data);

        this.setState({request: data, loaded: true});
    }

    getGender = (key) => {
        const gender = genders.filter((f) => f.key === key)[0];
        console.log("gender", gender);

        return gender;
    }
}

export default RequestThankyou;