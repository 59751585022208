import React from "react";
import {Image} from "react-bootstrap";
import styles from "../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import stylesFiatPro from "../../../../pages/forms/brands/FiatProForm/FiatPro.module.css";
import customFiatProStyles from "../../../../pages/forms/brands/FiatProForm/FiatPro.module.css";
import RequestModelList from "../RequestModelList";

class FiatRequestModelList extends RequestModelList {
    brandSlug = "fiat-professional";

    render() {
        const {loaded} = this.state;
        console.log("this.props", this.props);
        const {onModelClick} = this.props;

        const models = this.getFilteredModels();

        if (loaded) {
            return (
                <React.Fragment>
                    <div className={`${stylesFiatPro["section-header"]} ${stylesFiatPro["form-models-list"]}`}>
                        < h2 className={"text-center text-dark"}> Sélectionnez un modèle</h2>
                    </div>
                    <div className={styles["new-form-step-content-list-flex"]}>
                        {models.map((m) => (
                            <div className={`${styles["new-form-step-content-list-item"]} ${styles["wrap-car-model"]}`}

                                 key={m.id}>
                                <div className={customFiatProStyles["new-form-vehicle"]}  onClick={() => onModelClick(m)}
                                >
                                    <Image
                                        src={m.image && `${process.env.REACT_APP_BASE_API}/uploads/images/${m.image.filePath}`}
                                        className={`img-fluid ${styles["new-form-vehicle-image"]} mt-0 mb-0`}


                                    />
                                    <div className={`${customFiatProStyles["new-form-vehicle-title"]} `}
                                    > {m.name} </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}

export default FiatRequestModelList;
