import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../../pages/Home";
import NotFound from "../../../pages/errors/NotFound";
import { Redirect } from "react-router";
import JeepForm from "../../../pages/forms/brands/JeepForm";
import JeepThankYou from "../../../pages/forms/ThankYou";
import FiatProForm from "../../../pages/forms/brands/FiatProForm";
import FiatForm from "../../../pages/forms/brands/FiatForm";
import AlfaRomeoForm from "../../../pages/forms/brands/AlfaRomeoForm";
import AbarthForm from "../../../pages/forms/brands/AbarthForm";
import InformationRequest from "../../../pages/informationRequest";
import TonaleThankYouPage from "../../../pages/forms/TonaleThankYouPage";

const RouteList = () => {
  const [showComponent, setShowComponent] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 500);
  }, []);

  if (!showComponent) {
    return null;
  }

  return (
    <Switch>
      <Route path="/thankyou/:form/:request" component={JeepThankYou} exact />

      <Route
        path="/brands/alfaromeo/tonale"
        component={InformationRequest}
        exact
      />
      <Route
        path="/thankyou/alfaromeo/tonale"
        component={TonaleThankYouPage}
        exact
      />

      <Route path="/brands/alfaromeo/:form" component={AlfaRomeoForm} exact />

      <Route path="/brands/abarth/:form" component={AbarthForm} exact />

      <Route
        path="/brands/fiatprofessional/:form"
        component={FiatProForm}
        exact
      />

      <Route path="/brands/fiat/:form" component={FiatForm} exact />

      <Route path="/brands/jeep/:form" component={JeepForm} exact />

      <Route path="/" component={Home} exact />

      {/* NotFound */}
      <Route path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default RouteList;
