import React, { Component } from "react";
import styles from "../../../../../../pages/forms/brands/JeepForm/Jeep.module.css";

class JeepBrochureThankyou extends Component {
  render() {
    const { request } = this.props;
    console.log("request", request);
    return (
      <div className={styles["page-inner"]}>
        <div className={styles["section-header"]}>
          <h2>
            {" "}
            Merci{" "}
            <span className={styles["fiat-red-color"]}>
              {request.firstName} {request.lastName}
            </span>{" "}
            de l’intérêt que vous portez à notre marque.{" "}
          </h2>
          <span className={styles["dealer-informations"]}>
            <h4>
              Votre demande a été enregistrée.
              <br />
            </h4>
          </span>

          <div className={styles["thank-you-page-buttons"]}>
            <div className={styles["buttons-container-row-lead"]}>
              <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                <h2>Quoi d'autre ?</h2>
              </div>
              <div
                className={`${styles["new-icon-separator-container"]} d-flex align-items-center`}
              >
                {/*<div className={styles["separator"]}/>*/}
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${styles["new-icon-jeep_icon_brochure"]}`}
                  />
                  <div className={`${styles["copy-box"]} mb-0`}>
                    Téléchargez votre brochure. <br /> Vous allez également
                    recevoir un email avec un lien pour la télécharger.
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${styles["new-icon-jeep_icon_discovermodels"]}`}
                  />
                  <div className={`${styles["copy-box"]} mb-0`}>
                    Découvrez les brochures des autres modèles
                  </div>
                </div>
                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                  <div
                    className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${styles["new-icon-jeep_icon_discoverpromotions"]}`}
                  />
                  <div className={`${styles["copy-box"]} mb-0`}>
                    Découvrez toutes les promotions{" "}
                    {request.model &&
                      request.model.brand &&
                      request.model.brand.name}
                    !
                  </div>
                </div>
              </div>
              <div
                className={`${styles["new-form-submit"]} ${styles["submit-jeep"]} w-100 d-flex m-auto align-items-baseline justify-content-around`}
              >
                <a
                  className={"text-decoration-none"}
                  href={request.model && request.model.brochureUrl}
                >
                  Télécharger
                </a>

                <button
                  className={styles["rect-btn-grey"]}
                  onClick={() => {
                    window.parent.location.href =
                      "https://www.jeep.ma/shopping-tools/request-a-brochure";
                  }}
                >
                  En savoir plus
                </button>
                <button
                  className={styles["rect-btn-grey"]}
                  onClick={() => {
                    window.parent.location.href =
                      "https://www.jeep.ma/offres-suv-4x4";
                  }}
                >
                  Voir les offres
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JeepBrochureThankyou;
