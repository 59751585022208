import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Col, Image, Row} from "react-bootstrap";
import {FaChevronLeft} from "react-icons/all";
import NoteFormation from "../NoteFormation";
import RequestForm from "../RequestForm";
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import Disclaimer from "../Disclamer/Disclamer";
import Loader from "react-loader-spinner";

// import "../../../../assets/styles/form--fiat-pro--jett.css";

class JeepRequestForm extends RequestForm {
    componentDidMount() {
        console.log("component mounted");
        this.getCities();

        const {form} = this.props;
        const {data} = this.state;

        console.log('requestform_form', form);

        // if (form.key === 'brochure') {
        //     delete this.schema.telephone;
        //     delete this.schema.city;
        //
        //     delete data.telephone;
        //     delete data.city;
        //
        //     this.setState({data});
        // }
    }

    render() {

        const {model, onModelChange, form} = this.props;
        console.log('form name', form.name);
        const {loading} = this.state;

        return (
            <div>
                <div className={`${styles["section-header"]} ${styles["title-form"]}`}>
                    <h2>Remplissez le formulaire</h2>
                </div>
                <Row>
                    <div className={"col-lg-5"}>
                        <span className={styles['title-form']}>Votre sélection :</span>
                        {model && model.id && (
                            <div>
                                <p className={styles["new-form-img-title"]}>{model.name}</p>
                                <div className={styles["new-form-img"]}>
                                    <Image
                                        src={`${process.env.REACT_APP_BASE_API}/uploads/images/${model.image.filePath}`}
                                        className={`img-fluid {${styles["img-anagraphic"]} w-100`}
                                    />
                                    <Link
                                        className={`$styles{["new-form-link"]} ${styles["new-form-link-change"]} ${styles["model"]}`}
                                        onClick={() => onModelChange()}
                                    >
                                        <FaChevronLeft
                                            className={styles["new-icon-jeep_arrow_changemodel"]}
                                        />
                                        Changer de modèle
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={"col-lg-7"}>

                        <div className={styles["form-anagrafic-container"]}>
                            <form
                                onSubmit={this.handleSubmit}
                                className={`${styles["formAnagrafic"]} ${styles["mui-form"]}`}
                            >
                                <Row>
                                    <div className={"col-xs-12 col-sm-6"}>
                                        <div className={`${styles["new-select-form"]} required-field mt-5`}>
                                            {this.renderSelect("gender", "Civilité*", [
                                                {key: "", value: null},
                                                {key: "Mr.", value: "mister"},
                                                {key: "Mme.", value: "lady"},
                                                {key: "Mlle.", value: "miss"},
                                            ], `${styles['dropdownSelect']} ${styles["p-abs"]}`)}

                                            {/*<div className="dropdownSelect" data-name={"gender"}/>*/}
                                        </div>
                                    </div>
                                </Row>

                                <Row>
                                    <Col
                                        md={6}>{this.renderInput("firstName", "text", "Prénom*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    <Col
                                        md={6}>{this.renderInput("lastName", "text", "Nom*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={6}>{this.renderInput("email", "email", "Email*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    {/*{form.key !== 'brochure' && (*/}
                                    <Col
                                        md={6}>
                                        {/*className={form.name === 'brochure' ? 'd-none' : 'd-block'}*/}
                                        {this.renderInput("telephone", "tel", "Téléphone*", ` mui--is-empty mui--is-pristine mui--is-touched`, `mui-textfield mui-textfield--float-label required-field`)}</Col>
                                    {/*)}*/}
                                </Row>
                                {/*{form.key !== 'brochure' && (*/}
                                <Row>
                                    <Col md={6}>
                                        <div
                                            // ${form.name === 'brochure' ? 'd-none' : 'd-block'}
                                            className={`${styles["new-select-form"]} required-field mt-4`}>
                                            {this.renderSelect("city", "Ville*", this.getCity_list(), `${styles['dropdownSelect']}`)}
                                        </div>

                                    </Col>
                                    <Col md={6}></Col>
                                    {/*<Col>{this.renderInput("address", "text", "Adresse*", `mui--is-empty mui--is-pristine mui--is-touched}`, `mui-textfield mui-textfield--float-label required-field`)}</Col>*/}
                                </Row>
                                {/*)}*/}
                                <Row>
                                    <div className="col-xs-12 col-sm-6 pb-lg-3">
                                        <span
                                            className={styles["req-fields"]}>* Tous les champs sont obligatoires</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className={"col-xs-12"}>
                                        <div className={"new-form-text"}>
                                            <div
                                                className={`${styles["new-form-text-title"]} mt-3 mb-3`}>CONSENTEMENT
                                            </div>
                                            <div className={styles["new-form-text-content"]}>
                                                Après avoir lu la
                                                <NoteFormation/>
                                            </div>
                                            <div
                                                className={`${styles["fm-jeep"]} d-flex justify-content-start align-items-center`}>
                                                {this.renderRadioInput("acceptedMarketingActivity", " ", [
                                                    {key: "J’accepte", value: true},
                                                    {key: "Je refuse", value: false},
                                                ],)}

                                                <a
                                                    onClick={() => this.setDisclaimerOpen()}
                                                    aria-controls={styles["note-infos"]}
                                                    aria-expanded={this.state.isDisclaimerOpen}
                                                    className={`${styles["new-form-linktext"]} m-0 float-end`}
                                                >
                                                    <u>Conditions générales d'utilisation</u>
                                                </a>
                                            </div>
                                            <Disclaimer open={this.state.isDisclaimerOpen}/>
                                        </div>
                                    </div>
                                </Row>
                                <div>
                                    <div className={`${styles['new-form-submit']} ${styles['submit-jeep']}`}>
                                        {!loading ? (
                                            this.renderButton("ÉTAPE SUIVANTE", {color: "#000"})
                                        ) : (
                                            <div style={{textAlign: 'center'}}>
                                                <Loader
                                                    type="TailSpin"
                                                    color="#00BFFF"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Row>

            </div>
        );
    }
}

export default JeepRequestForm;
