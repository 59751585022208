import React, {Component} from "react";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";
import showroomStyles from "../../../../pages/forms/brands/AbarthForm/Abarth.module.css";
import {Card, Col, Row} from "react-bootstrap";
import {FaMapMarkerAlt, FaPhoneAlt} from "react-icons/fa";

class AbarthRequestShowroomList extends Component {
    state = {
        request: {},
        showrooms: [],
        loaded: false,
        hasShowroom: false,
    };

    componentDidMount() {
        const {request} = this.props;

        if (request && request.city) {
            const {request} = this.props;
            this.setState({request});

            this.getShowroomsByCity(request.city);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.request !== this.props.request && this.props.request.city) {
            const {request} = this.props;

            this.getShowroomsByCity(request.city);

            this.setState({request: request});
        }
    }

    handleShowroomClick = async (showroom) => {
        // set showroom
        const {request} = this.state;
        const {form} = this.props;

        const apiHelper = new ApiResourceHelper(form.endpoint);
        const {data: requestUpdated} = await apiHelper.update(
            `${form.endpoint}/${request.id}`,
            {
                ...request,
                showroom: `/showrooms/${showroom.id}`,
                model: `/models/${request.model.id}`,
                city: `/cities/${request.city.id}`,
            }
        );

        this.setState({request: requestUpdated});

        const {onComplete} = this.props;
        // switch to the next accordion item
        onComplete();
    };

    getShowrooms = async () => {
        const showroomHelper = new ApiResourceHelper("/showrooms");
        const {data: showrooms} = await showroomHelper.findAll();

        this.setState({showrooms, loaded: true});
    };

    getShowroomsByCity = async (city) => {
        const showroomHelper = new ApiResourceHelper("/showrooms");
        const {data: showrooms} = await showroomHelper.findBy([
            {key: "city", value: `/cities/${city.id}`},
        ]);


        if (showrooms.length) {
            this.setState({showrooms, loaded: true, hasShowroom: true});
        } else {
            this.getShowrooms();
        }

    };

    render() {
        const {showrooms, loaded, hasShowroom, request} = this.state;
        if (loaded) {
            return (
                <div>
                    <div className={`${showroomStyles["section-header"]} ${showroomStyles["title-form"]}`}>
                        <h2 className={"text-start"}>
                            Sélectionnez un de nos distributeurs
                        </h2>
                    </div>
                    {hasShowroom && request.city && (
                        <Row>
                            <div className={`${showroomStyles["showroom-city"]} text-uppercase mb-3`}>
                                {request.city.name}
                            </div>
                        </Row>
                    )}
                    <Row>

                        {showrooms.map((s) => (
                            <Col md={6} key={s.id} className={"mb-5"}>
                                <Card className={`shadow-sm p-3 w-100 ${showroomStyles["showroom-card"]}`}
                                      onClick={() => this.handleShowroomClick(s)}>

                                    {!hasShowroom && s.city && (
                                        <p className={`${showroomStyles["showroom-city"]} text-uppercase fw-bold pb-4`}>
                                            {s.city.name}
                                        </p>
                                    )}

                                    <p className={`${showroomStyles["showroom-title"]} text-uppercase fw-bold pb-4`}>
                                        {s.name}
                                    </p>
                                    <p className={`${showroomStyles["showroom-address"]} text-uppercase fw-bold pb-4`}>
                                        <FaMapMarkerAlt/> {s.address}
                                    </p>
                                    <p className={`${showroomStyles["showroom-phone"]} text-uppercase fw-bold pb-4`}>
                                        <FaPhoneAlt/> {s.telephone}{s.telephoneSecondary && (<React.Fragment>
                                        / {s.telephoneSecondary}
                                    </React.Fragment>)}

                                    </p>
                                    {/*<a className={`${showroomStyles["rect-btn-red"]} ${showroomStyles["abarthpro-btn"]} ${showroomStyles["btn-red-shadow"]} `}*/}
                                    <div className={showroomStyles["submit-abarth"]}>
                                        <button
                                            onClick={() => this.handleShowroomClick(s)}>
                                            Séléctionez
                                        </button>
                                    </div>

                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            );
        } else {
            return <div>Loading...</div>;
        }
    }
}

export default AbarthRequestShowroomList;
