import React, {useState} from 'react';

const RadioInput = ({name, label, error,value, options,inlineCSS, classes, containerClasses, customStyle, ...rest}) => {
    const [correct, setCorrect] = useState(false);

    const isCorrect = () => {
        if (!error && value !== '') setCorrect(true);
        else setCorrect(false);
    }
    return (
        <div className="form-group mb-0 pt-1">

            <label htmlFor="acceptedMarketingActivity">{label}</label>
            <div className={"new-form-radios"}>
                {options.map((o) => {
                    return (
                        <div className="form-check form-check-inline">
                            <div>
                                <label className="form-check-label"/>
                                <input {...rest} name={name} value={o.value} type="radio" className={`form-check-input ${error && "border-red"} ${correct && "border-green"} ${classes}`} style={inlineCSS}/>
                                {o.key}
                            </div>
                        </div>
                    );
                })}
            </div>

            {error && <div className="alert alert-danger">{error}</div>}

        </div>
    );
};

export default RadioInput;
