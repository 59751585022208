import React from "react";
import RequestThankyou from "../../../components/App/forms/RequestThankyou";
import FiatEssaiThankYou from "../../../components/App/forms/thankyous/FiatThankyou";
import JeepEssaiThankyou from "../../../components/App/forms/thankyous/JeepThankyou";
import AlfaromeoEssaiThankYou from "../../../components/App/forms/thankyous/AlfaromeoThankyou/infdex";
import FiatProEssaiThankYou from "../../../components/App/forms/thankyous/FiatProThankyou";
import JeepBrochureThankyou from "../../../components/App/forms/thankyous/JeepThankyou/brochure";
import FiatBrochureThankyou from "../../../components/App/forms/thankyous/FiatThankyou/brochure";
import AlfaromeoBrochureThankyou from "../../../components/App/forms/thankyous/AlfaromeoThankyou/brochure";
import JeepDevisThankyou from "../../../components/App/forms/thankyous/JeepThankyou/devis";
import FiatDevisThankYou from "../../../components/App/forms/thankyous/FiatThankyou/devis";
import FiatProDevisThankYou from "../../../components/App/forms/thankyous/FiatProThankyou/devis";
import FiatProBrochureThankYou from "../../../components/App/forms/thankyous/FiatProThankyou/brochure";
import AlfaromeoDevisThankYou from "../../../components/App/forms/thankyous/AlfaromeoThankyou/devis";
import AbarthBrochureThankyou from "../../../components/App/forms/thankyous/AbarthThankyou/brochure";
import AbarthDevisThankYou from "../../../components/App/forms/thankyous/AbarthThankyou/devis";
import AbarthEssaiThankYou from "../../../components/App/forms/thankyous/AbarthThankyou";

class ThankYou extends RequestThankyou {

    render() {
        const {request, loaded} = this.state;

        const form = this.getCurrentForm();

        if (loaded) {
            if (request.model.brand) {
                switch (request.model.brand.slug) {
                    case "fiat":
                        switch (form.key) {
                            case "brochure":
                                return <FiatBrochureThankyou request={request}/>
                                break;
                            case "devis":
                                return <FiatDevisThankYou request={request}/>
                                break;
                            case "essai":
                                return <FiatEssaiThankYou request={request}/>;
                                break;
                        }
                        break;
                    case "fiat-professional":
                        switch (form.key) {
                            case "brochure":
                                return <FiatProBrochureThankYou request={request}/>
                                break;
                            case "devis":
                                return <FiatProDevisThankYou request={request}/>;
                                break;
                            case "essai":
                                return <FiatProEssaiThankYou request={request}/>;
                                return <FiatProEssaiThankYou request={request}/>;
                                break;
                        }
                        break;
                    case "jeep":
                        switch (form.key) {
                            case "brochure":
                                return <JeepBrochureThankyou request={request}/>;
                                break;
                            case "devis":
                                return <JeepDevisThankyou request={request}/>
                                break;
                            case "essai":
                                return <JeepEssaiThankyou request={request}/>;
                                break;
                        }
                        break;
                    case "alfa-romeo":
                        switch (form.key) {
                            case "brochure":
                                return <AlfaromeoBrochureThankyou request={request}/>
                                break;
                            case "devis":
                                return <AlfaromeoDevisThankYou request={request}/>
                                break;
                            case "essai":
                                return <AlfaromeoEssaiThankYou request={request}/>
                                break;
                        }
                        break;

                        case "abarth":
                            switch (form.key) {
                                case "brochure":
                                    return <AbarthBrochureThankyou request={request}/>
                                    break;
                                case "devis":
                                    return <AbarthDevisThankYou request={request}/>
                                    break;
                                case "essai":
                                    return <AbarthEssaiThankYou request={request}/>
                                    break;
                            }
                            break;
                }
            }
        }

        return <div>En cours...</div>
    }
}

export default ThankYou;