import React from "react";

const Button = ({type, label, cssClass, inlineCSS, ...rest}) => {
    console.log("inlineCSS", inlineCSS);
    return (
        <button className={cssClass} style={inlineCSS ? inlineCSS : {}} rest>
            {label}
        </button>
    );
};

export default Button;
