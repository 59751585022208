import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import styles from "../../../../pages/forms/brands/JeepForm/Jeep.module.css";
import "../../../../assets/styles/note-info.css";

export default function NoteFormation() {
    const [open, setOpen] = useState(false);
    return (
        <>
            <a
                onClick={() => setOpen(!open)}
                aria-controls={styles["note-infos"]}
                aria-expanded={open}
                className={styles["new-form-linktext"]}
            >
                <u>note d'information</u>
            </a>
            <Collapse in={open} className={"mt-3"}>
                <div id={styles["note-infos"]}>
                    <div className={"form-radios-group-infos-content"}>
                        <div className="privacyMask"></div>

                        {/*<iframe*/}
                        {/*    src="https://staticpromo.fiat.com/privacy/FR/informative-fr.html"*/}
                        {/*    data-gtm-yt-inspected-1_27="true"*/}
                        {/*    style={{width: "100%", height: "155px"}}*/}
                        {/*/>*/}

                        <div className={"boxMessage"}>
                            <p className={"privacy-title pt-3 pb-0"}>
                               <u> NOTE D’INFORMATION</u>
                            </p>
                        </div>


                        <p className={"privacy-title"}>FINALITÉ ET MODALITÉS DU TRAITEMENT DES DONNÉES</p>

                        <p>
                        
                        L’Internaute reconnaît et accepte que les données collectées dans le cadre de 
                        l’utilisation par ce dernier du Site fassent l'objet d'un traitement sous forme de fichier papier ou électronique. 
                        Les informations nominatives concernant l’Internaute, dites données à caractère personnel (nom, prénom, numéro de téléphone, adresse etc…) (ci-après « Données ») 
                        pourront être traitées, stockées communiquées à nos tiers sous-traitants ainsi qu’à notre société mère Fiat Spa, dans le respect des dispositions de la loi 09-08 et des règles de sécurité et de confidentialité desdites Données. 
                        Elles pourront aussi être communiquées sur réquisition des autorités judiciaires.


                        </p>

                        <p>
                        L’Internaute nous autorise à utiliser les données collectées, 
                        par courrier électronique ou tout autre moyen employant une technologie de même nature, 
                        à des fins commerciales et/ou promotionnelles, 
                        envoyer des documents publicitaires, effectuer des activités de vente directe ou de communication commerciales interactives sur des produits, 
                        des services et d’autres activités, ou bien effectuer des études de marché.

                        </p>

                        <p className={"privacy-title"}>
                        CONSÉQUENCES DE LA NON FOURNITURE DE DONNÉES
                        </p>

                        <p>
                        La communication de vos données à caractère personnel est facultative, 
                        toutefois veuillez noter qu’en l’absence de ces informations, 
                        le service demandé ne pourra pas être pris en compte et vous ne pouvez pas être identifié correctement.
                        </p>

                        <p className={"privacy-title"}>
                        TIERS SUSCEPTIBLES D’AVOIR CONNAISSANCE DE VOS DONNÉES PERSONNELLES
                        </p>

                        <p className={"privacy-title"}>
                        VOS DROITS
                        </p>

                        <p>
                        Conformément à la loi 09-08, vous avez le droit d’accéder à vos données à caractère personnel, d’obtenir la correction de toute donnée traitée ou de vous opposer pour motif légitime au traitement de vos données. Vous pouvez également vous opposer à l’utilisation de vos données à caractère personnel à des fins commerciales ou marketing. Vous avez également le droit de demander la liste complète et à jour de l’ensemble des sous-traitants de ces données. Vous pouvez exercer ces droits en écrivant à FIAT CHRYSLER AUTOMOBILES MOROCCO, Ouled Benameur, RP 3011, KM 6, Bouskoura (seules les demandes signées, accompagnées de la photocopie d’une pièce d’identité seront traitées).
                        
                        Ce traitement de données a été déclaré auprès de la CNDP sous le numéro D-M-344/2017

                        </p>
                    </div>
                </div>
            </Collapse>
        </>
    );
}