import React, {Component} from "react";
import {FaChevronRight} from "react-icons/fa";

// import styles from "../../forms/brands/FiatForm/Abarth.module.css";
import styles from "../../../../../../pages/forms/brands/FiatForm/Fiat.module.css";
import customStyle from "../../../../../../pages/forms/brands/AlfaRomeoForm/AlfaRomeo.module.css";

class AbarthDevisThankYou extends Component {
    render() {
        const {request} = this.props;
        console.log('request', request);
        return (
            <div className={`${styles["page-inner"]}`} style={{margin: '0 30px'}}>
                <div className={styles["section-header"]} style={{color:'#FFFFFF'}}>
                    <h2 style={{color:'#FFFFFF', textAlign:'center',padding:'20px 0'}} > Merci <span
                        className={styles["fiat-red-color"]}>{request.firstName} {request.lastName}</span> de l’intérêt
                        que vous portez à notre
                        marque. </h2>
                    Abarth
                    <span className={styles["dealer-informations"]}>
                            <h4 className={'fs-6 pt-4 pb-3'}>Votre demande a été enregistrée.<br/><br/>
                                {request.showroom && (
                                    <React.Fragment>
                                        {request.showroom.name}
                                        <br/> {request.showroom.address}
                                    </React.Fragment>
                                )}
                                </h4>
                        </span>

                    <div className={styles["thank-you-page-buttons"]}>
                        <div className={styles["buttons-container-row-lead"]}>
                            <div className={`${styles["copy-box"]} ${styles["answerTitle"]}`}>
                                <h4 style={{color:'#FFFFFF', textAlign:'center',padding:'20px 0', fontWeight:'bold'}}>Quoi d'autre ?</h4>
                            </div>
                            <div className={`${styles["new-icon-separator-container"]} d-flex flex-wrap`}>

                                {/*<div className={styles["separator"]}/>*/}
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico1"]} ${customStyle["new-icon-alfa-romeo_icon_quote"]}`}/>
                                    <div className={styles["copy-box"]} style={{color:'#FFFFFF'}}>Le Distributeur a reçu votre requête
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico2"]} ${customStyle["new-icon-alfa-romeo_icon_calendar"]}`}/>
                                    <div className={styles["copy-box"]} style={{color:'#FFFFFF'}}>Vous serez contacté(e) dans les plus
                                        brefs délais, <br/> conformément au décret gouvernemental relatif au
                                        COVID-19, <br/> afin de
                                        convenir d’un rendez-vous
                                    </div>
                                </div>
                                <div className={"col-xs-12 col-sm-4 cta1 col-md-4"}>
                                    <div
                                        className={`${styles["copy-box-ico"]} ${styles["ico3"]} ${customStyle["new-icon-alfa-romeo_icon_discoverdealers"]}`}/>
                                    <div className={styles["copy-box"]} style={{color:'#FFFFFF'}}>Vous allez découvrir votre Abarth <br/> chez
                                        votre
                                        Distributeur <br/> et recevoir une offre personnalisée
                                    </div>
                                </div>
                            </div>
                            <div className={`mt-lg-5`}>
                                <button
                                    onClick={() => {
                                        window.parent.location.href = "https://www.abarth.ma/"
                                    }}
                                    className={customStyle["anagraphic-btn"]}
                                >
                                    Revenir à l'accueil <FaChevronRight/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AbarthDevisThankYou;
