import React, {Component} from "react";
import FiatProRequestModelList from "../../../../components/App/forms/FiatProRequestModelList";
import FiatProRequestForm from "../../../../components/App/forms/FiatProRequestFrom";
import BaseFormPage from "../../../../components/App/forms/BaseFormPage";
import styles from "../FiatForm/Fiat.module.css";
import styleFiatPro from "./FiatPro.module.css";
import FiatRequestShowroomList from "../../../../components/App/forms/FiatRequestShowroomList/index";
import {Container} from "react-bootstrap";
import FiatProBrochureRequestForm from "../../../../components/App/forms/FiatProBrochureRequestFrom";
import {FaCheck} from "react-icons/all";

export default class FiatProForm extends BaseFormPage {
    title = "Fiat Professional";

    render() {
        // const {currentItem, model, request} = this.state;
        const {currentItem, model, request, form} = this.state;
        console.log("state.form", form);
        return (
            <div>
                <Container>
                    {/*<div>*/}
                    {/*    <div className={`${styles["form-title"]} ${styles["page-inner"]}`}>*/}
                    {/*        <h1 className={`${styles["h1-form-type"]} mt-3 mb-5`}>Demandez {form.name === 'brochure' ? "une" : "un"} {form.name}</h1>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {form.key !== 'brochure' && (
                        <div className={`${styles["form-wizard"]} w-50 d-block m-auto text-center `}>
                            <ol className={`${styles["step-indicator"]} ${styleFiatPro["fw-fiatpro"]}`}>
                                <li className={`${(currentItem === 'profile' || currentItem === 'showroom') && styles["complete"]} ${currentItem === 'model' && styles["active-step"]} `}>
                                    <div className={styles["step"]}>
                                        <span>{currentItem === 'model' && 1} {(currentItem === 'profile' || currentItem === 'showroom') &&
                                        <FaCheck/>}</span>

                                    </div>
                                    <div
                                        className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}>
                                        <span className={`${currentItem === 'model' && 'text-dark'}`}>MODÈLE</span>
                                    </div>
                                </li>

                                <li className={`${(currentItem === 'showroom' && styles["complete"])}  ${currentItem === 'profile' && styles["active-step"]} `}>
                                    <div
                                        className={styles["step"]}><span>{(currentItem === 'profile' || currentItem === 'model') && 2} {currentItem === 'showroom' &&
                                    <FaCheck/>}</span></div>
                                    <div
                                        className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]} `}>
                                        <span
                                            className={`${currentItem === 'profile' && 'text-dark'}`}>VOS COORDONNÉES</span>
                                    </div>
                                </li>

                                <li className={`${currentItem === 'showroom' && styles["active-step"]}`}>
                                    <div className={styles["step"]}>
                                        {/*<span>{(currentItem === 'profile' || currentItem === 'model' || currentItem === 'showroom') && 3} {currentItem === 'showroom' &&*/}
                                        {/*<FaCheck/>}</span>*/}
                                        <span>3</span>
                                    </div>
                                    <div
                                        className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}>
                                        <span
                                            className={`${currentItem === 'showroom' && 'text-dark'}`}>DISTRIBUTEUR</span>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    )}


                    {form.key === 'brochure' && (
                        <div className={`${styles["form-wizard"]} w-50 d-block m-auto text-center `}>
                            <ol className={styles["step-indicator"]}>
                                <li className={`${(currentItem === 'profile' || currentItem === 'showroom') && styles["complete"]} ${styles["active-step"]}`}>
                                    <div className={styles["step"]}>
                                    <span>  {currentItem === 'model' && 1} {currentItem === 'profile' &&
                                    <FaCheck/>}</span>
                                    </div>
                                    <div
                                        className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}>
                                        <span className={`${currentItem === 'model' && 'text-dark'}`}>MODÈLE</span>
                                    </div>
                                </li>
                                <li className={`${currentItem === 'showroom' && styles["complete"]} ${currentItem === 'profile' && styles["active-step"]}`}>
                                    <div className={styles["step"]}>
                                        <span> 2 </span></div>
                                    <div
                                        className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}>
                                        <span
                                            className={`${currentItem === 'profile' && 'text-dark'}`}>VOS COORDONNÉES</span>
                                    </div>
                                </li>
                            </ol>
                        </div>

                    )}
                </Container>


                <Accordion>
                    <AccordionItem
                        item={{
                            id: 1,
                            key: "model",
                            label: "Modèle",
                            visible: currentItem === "model",
                            checked:
                                currentItem === "profile" || currentItem === "showroom",
                        }}
                    >
                        <FiatProRequestModelList
                            form={form}
                            onModelClick={this.handleModelClick}
                            getCurrentModel={this.getCurrentModel}
                            currentStep={currentItem}
                        />
                    </AccordionItem>

                    <AccordionItem
                        item={{
                            id: 2,
                            key: "profile",
                            label: "Vos coordonées",
                            visible: currentItem === "profile",
                            checked: currentItem === "showroom",
                        }}
                    >
                        {form.key !== 'brochure' && (
                            <FiatProRequestForm
                                form={form}
                                model={model}
                                onSwitch={this.switchAccordionItem}
                                setRequest={this.setRequest}
                                onModelChange={this.handleModelChange}
                            />)}

                        {form.key === 'brochure' && (
                            <FiatProBrochureRequestForm
                                form={form}
                                model={model}
                                onComplete={this.complete}
                                setRequest={this.setRequest}
                                onModelChange={this.handleModelChange}
                            />)}
                    </AccordionItem>

                    <AccordionItem
                        item={{
                            id: 3,
                            key: "showroom",
                            label: "Distributeur",
                            visible: currentItem === "showroom",
                            checked: false,
                        }}
                    >
                        <FiatRequestShowroomList
                            form={form}
                            request={request}
                            onComplete={this.complete}
                        />
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

class Accordion extends Component {
    render() {
        return (
            <React.Fragment>
                <div className={styles["accordion"]}>{this.props.children}</div>
            </React.Fragment>
        );
    }
}

class AccordionItem extends Component {
    render() {
        const {item} = this.props;
        const activeStatus = item.visible ? styles["active"] : "";

        console.log("item", item);

        return (
            <React.Fragment>
                <div className={`${styles["accordion__content"]} ${activeStatus}`}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}
