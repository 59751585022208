import React, { Component } from "react";
import FiatRequestModelList from "../../../../components/App/forms/FiatRequestModelList";
import FiatRequestForm from "../../../../components/App/forms/FiatRequestFrom";
import BaseFormPage from "../../../../components/App/forms/BaseFormPage";
import styles from "./Fiat.module.css";
import FiatRequestShowroomList from "../../../../components/App/forms/FiatRequestShowroomList";
import { Container } from "react-bootstrap";
import FiatBrochureRequestForm from "../../../../components/App/forms/FiatBrochureRequestFrom";
import { FaCheck } from "react-icons/all";

export default class FiatForm extends BaseFormPage {
  title = "Fiat";

  render() {
    // const {currentItem, model, request} = this.state;
    const { currentItem, model, request, form } = this.state;
    console.log("state.form", form);
    return (
      <Container id="fiat">
        <div className={styles["fiat-theme"]}>
          <div>
            <div className={`${styles["form-title"]} ${styles["page-inner"]}`}>
              <h1 className={`${styles["h1-form-type"]} mt-5 mb-5`}>
                Demandez {form.name === "brochure" ? "une" : "un"} {form.name}
              </h1>
            </div>
          </div>
          {form.key !== "brochure" && (
            <div
              className={`${styles["form-wizard"]} w-50 d-block m-auto text-center `}
            >
              <ol className={styles["step-indicator"]}>
                <li
                  className={`${
                    (currentItem === "profile" || currentItem === "showroom") &&
                    styles["complete"]
                  } ${currentItem === "model" && styles["active-step"]} `}
                >
                  <div className={styles["step"]}>
                    <span
                      className={`${currentItem === "model" && "text-dark"}`}
                    >
                      {currentItem === "model" && 1}{" "}
                      {(currentItem === "profile" ||
                        currentItem === "showroom") && <FaCheck />}
                    </span>
                  </div>
                  <div
                    className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}
                  >
                    <span
                      className={`${currentItem === "model" && "text-dark"}`}
                    >
                      MODÈLE
                    </span>
                  </div>
                </li>

                <li
                  className={`${
                    currentItem === "showroom" && styles["complete"]
                  }  ${currentItem === "profile" && styles["active-step"]} `}
                >
                  <div className={styles["step"]}>
                    <span>
                      {(currentItem === "profile" || currentItem === "model") &&
                        2}{" "}
                      {currentItem === "showroom" && <FaCheck />}
                    </span>
                  </div>
                  <div
                    className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]} `}
                  >
                    <span
                      className={`${currentItem === "profile" && "text-dark"}`}
                    >
                      VOS COORDONNÉES
                    </span>
                  </div>
                </li>

                <li
                  className={`${
                    currentItem === "showroom" && styles["active-step"]
                  }`}
                >
                  <div className={styles["step"]}>
                    {/*<span>{(currentItem === 'profile' || currentItem === 'model' || currentItem === 'showroom') && 3} {currentItem === 'showroom' &&*/}
                    {/*<FaCheck/>}</span>*/}
                    <span>3</span>
                  </div>
                  <div
                    className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}
                  >
                    <span
                      className={`${currentItem === "showroom" && "text-dark"}`}
                    >
                      DISTRIBUTEUR
                    </span>
                  </div>
                </li>
              </ol>
            </div>
          )}

          {form.key === "brochure" && (
            <div
              className={`${styles["form-wizard"]} w-50 d-block m-auto text-center `}
            >
              <ol className={styles["step-indicator"]}>
                <li
                  className={`${
                    (currentItem === "profile" || currentItem === "showroom") &&
                    styles["complete"]
                  } ${styles["active-step"]}`}
                >
                  <div className={styles["step"]}>
                    <span>
                      {" "}
                      {currentItem === "model" && 1}{" "}
                      {currentItem === "profile" && <FaCheck />}
                    </span>
                  </div>
                  <div
                    className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}
                  >
                    <span
                      className={`${currentItem === "model" && "text-dark"}`}
                    >
                      MODÈLE
                    </span>
                  </div>
                </li>
                <li
                  className={`${
                    currentItem === "showroom" && styles["complete"]
                  } ${currentItem === "profile" && styles["active-step"]}`}
                >
                  <div className={styles["step"]}>
                    <span> 2 </span>
                  </div>
                  <div
                    className={`${styles["caption"]} ${styles["hidden-xs"]} ${styles["hidden-sm"]}`}
                  >
                    <span
                      className={`${currentItem === "profile" && "text-dark"}`}
                    >
                      VOS COORDONNÉES
                    </span>
                  </div>
                </li>
              </ol>
            </div>
          )}

          <div>
            <Accordion>
              <AccordionItem
                item={{
                  id: 1,
                  key: "model",
                  label: "Modèle",
                  visible: currentItem === "model",
                  checked:
                    currentItem === "profile" || currentItem === "showroom",
                }}
              >
                <FiatRequestModelList
                  form={form}
                  onModelClick={this.handleModelClick}
                  getCurrentModel={this.getCurrentModel}
                  currentStep={currentItem}
                />
              </AccordionItem>

              <AccordionItem
                item={{
                  id: 2,
                  key: "profile",
                  label: "Vos coordonées",
                  visible: currentItem === "profile",
                  checked: currentItem === "showroom",
                }}
              >
                {form.key !== "brochure" && (
                  <FiatRequestForm
                    form={form}
                    model={model}
                    onSwitch={this.switchAccordionItem}
                    setRequest={this.setRequest}
                    onModelChange={this.handleModelChange}
                  />
                )}

                {form.key === "brochure" && (
                  <FiatBrochureRequestForm
                    form={form}
                    model={model}
                    onComplete={this.complete}
                    setRequest={this.setRequest}
                    onModelChange={this.handleModelChange}
                  />
                )}
              </AccordionItem>
              {form.key !== "brochure" && (
                <AccordionItem
                  item={{
                    id: 3,
                    key: "showroom",
                    label: "Distributeur",
                    visible: currentItem === "showroom",
                    checked: false,
                  }}
                >
                  <FiatRequestShowroomList
                    form={form}
                    request={request}
                    onComplete={this.complete}
                  />
                </AccordionItem>
              )}
            </Accordion>
          </div>
        </div>
      </Container>
    );
  }
}

class Accordion extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={styles["accordion"]}>{this.props.children}</div>
      </React.Fragment>
    );
  }
}

class AccordionItem extends Component {
  render() {
    const { item } = this.props;
    const activeStatus = item.visible ? styles["active"] : "";

    console.log("item", item);

    return (
      <React.Fragment>
        <div className={`${styles["accordion__content"]} ${activeStatus}`}>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}
