import React, { Component } from "react";
import ApiResourceHelper from "../../../../helpers/ApiResourceHelper";

class RequestModelList extends Component {
  state = {
    models: [],
    loaded: false,
  };

  componentDidMount() {
      this.getModels();
  }

  getModels = async () => {
    const modelHelper = new ApiResourceHelper("/models");
    const { data: models } = await modelHelper.findBy([
      {key: "brand.slug", value: this.brandSlug},
      {key: "order[position]", value: 'asc'},
    ]);

    this.setState({ models, loaded: true });

    const {getCurrentModel} = this.props;

    const currentModel = models.filter((model) => model.slug === getCurrentModel())[0];

    const {currentStep, onModelClick} = this.props;

    if (currentModel && currentStep === 'model') {
      onModelClick(currentModel);
    }
  };

  getFilteredModels = () => {
    const {form} = this.props;
    const {models} = this.state;

    let filteredModels = models;

    if (form.key === 'brochure') {
      filteredModels = models.filter((model) => model.document && model.image);
    } else {
      filteredModels = models.filter((model) => model.image);
    }

    return filteredModels;
  }
}

export default RequestModelList;
