import React, {Component} from "react";
import forms from "../../../../config/forms.json";
import * as queryString from "query-string";

export default class BaseFormPage extends Component {
    state = {
        currentItem: "model",
        form: {},
        model: {},
        request: {},
    };

    componentDidMount() {
        this.getCurrentForm();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.key !== this.props.location.key) {
            this.getCurrentForm();
            this.setState({currentItem: "model"});
        }
    }

    getCurrentForm = () => {
        console.log('I arrived here');
        const formKey = this.props.match.params.form;
        console.log('formKey', formKey);

        const form = forms.filter((f) => f.key === formKey)[0];
        console.log("form", form);

        this.setState({form});

        if (["Abarth", "Jeep"].includes(this.title)) {
            document.body.style.backgroundColor = "#333333"
        }
    };

    handleModelClick = (model) => {
        // set model
        //console.log("model clicked");
        //console.log("model chosen", model);

        this.setState({model});

        // switch to the next accordion item
        this.switchAccordionItem("profile");
    };

    handleModelChange = () => {
        // switch back to the previous accordion item : model
        this.switchAccordionItem("model");
    };

    getModel = () => {
        //console.log("getModel", this.state.model);
        return this.state.model;
    };

    setRequest = (request) => {
        //console.log("setRequest called");
        //console.log("getRequest", request);
        this.setState({request});
    };

    getRequest = () => {
        //console.log("getRequest called");
        //console.log("getRequest", this.state.request);
        return this.state.request;
    };

    switchAccordionItem = (currentItem) => {
        this.setState({currentItem});
    };

    complete = () => {
        //console.log("form completed");
        const {form, request} = this.state;
        this.props.history.replace(`/thankyou/${form.key}/${request.id}`);
    };

    getCurrentModel = () => {
        const {location} = this.props;
        const params = queryString.parse(location.search);
        console.log('params', params);
        console.log('params.model', params.model);

        return params.model;
    }
}
